import { type CommonMedia } from '@lp-lib/api-service-client/public';
import { type Media } from '@lp-lib/media';

import { mapLinearVolumeToLogarithmic } from '../../../services/audio/utils';
import { fromMediaDTO } from '../../../utils/api-dto';
import { xDomainifyUrl } from '../../../utils/common';
import { MediaUtils } from '../../../utils/media';
import { UnplayableAudioImpl } from '../../../utils/unplayable';
import { type TrackId, TrackInitConfigBuilder } from '../../VideoMixer';
import { makeMediaElementTracker } from '../../VoiceOver/makeLocalAudioOnlyVideoMixer';

const fadeDurationMs = 300;
// When tweaking this, imagine a slider that goes from 0 to 1, where 0 is muted,
// 1 is max volume. We use a logarithmic scale to make the slider more akin to
// the human ear. That is, an input value of 0.5 is going to output a volume
// that will sound about "half as loud" as 1. Without the logarithmic transform,
// a value of 0.5 would actually sound much louder than "half as loud" as 1.
const musicVolume = mapLinearVolumeToLogarithmic(0.5, 0, 1);

export class MusicControl {
  // Note that on IOS, `web-audio` will cause the audio to be cutoff slightly at
  // the beginning and the end of the track. Unfortunately this tradeoff is the
  // only way for the music to be "background" volume.
  readonly localVM = makeMediaElementTracker('web-audio');
  readonly vm = this.localVM[0];

  private currentMedia: Nullable<Media>;
  private currentTrackId: Nullable<TrackId>;

  play(): void {
    this.vm.play();
  }

  async switchTrack(incoming: Nullable<Media | CommonMedia>) {
    const media = fromMediaDTO(incoming);
    const url = MediaUtils.PickMediaUrl(media);
    if (this.currentMedia && (!url || !media)) {
      // there is no bg music for this slide, fade out the current and reset
      // currentMedia so that subsequent calls to switchTrack will not be
      // ignored due to a matching has check. [LP-3339]
      this.fadeOutCurrentTrack();
      this.currentMedia = null;
      return;
    }

    if (!url || !media) {
      return;
    }

    if (this.currentMedia?.hash === media.hash) {
      return;
    }

    const unplayable = UnplayableAudioImpl.FromWebAudioPool(xDomainifyUrl(url));
    await unplayable.intoPlayable();
    const durationMs = unplayable.media?.duration * 1000;
    this.fadeOutCurrentTrack();

    const trackInitConfig = new TrackInitConfigBuilder()
      .setTimelineTimeStartMs(this.vm.playheadMs)
      .setDurationMs(durationMs)
      .addAudioGainEnvelope(
        { ms: 0, value: 0 },
        { ms: fadeDurationMs, value: musicVolume },
        'linear'
      )
      .setLoop(true)
      .build();

    this.currentMedia = media;
    this.currentTrackId = this.vm.pushTrack(unplayable.media, trackInitConfig);
  }

  private fadeOutCurrentTrack() {
    if (this.currentTrackId) {
      const trackElapsedTimeMs = this.vm.getTrackElapsedTimeMs(
        this.currentTrackId
      );
      if (trackElapsedTimeMs !== null) {
        this.vm.patchTrack(this.currentTrackId, {
          audioEffects: {
            gain: {
              kind: 'gain',
              trackLocalEnvelopes: [
                {
                  start: {
                    ms: trackElapsedTimeMs,
                    value: musicVolume,
                  },
                  end: {
                    ms: trackElapsedTimeMs + fadeDurationMs,
                    value: 0,
                  },
                  curve: 'linear',
                },
              ],
            },
          },
          timelineTimeEndMs: this.vm.playheadMs + fadeDurationMs,
        });
      }
    }
  }

  pause(): void {
    this.vm.pause();
  }

  destroy(): void {
    this.vm.destroy();
  }
}
