import cloneDeep from 'lodash/cloneDeep';

import { type MultipleChoiceBlock } from '@lp-lib/game';
import type { BlockOutputsDesc } from '@lp-lib/game/src/block-outputs';

const outputSchema: BlockOutputsDesc = {
  choice: {
    name: 'choice',
    description: 'Set when the user selects one of the presented choices.',
    schema: {
      type: 'string',
    },
  },
  grade: {
    name: 'grade',
    description: 'Set when the block evaluates the user selection.',
    schema: {
      type: 'enum',
      values: ['correct', 'incorrect'],
    },
  },
};

export function getOutputSchema(block: MultipleChoiceBlock) {
  const schema = cloneDeep(outputSchema);
  schema.choice.schema = {
    type: 'enum',
    values: block.fields.answerChoices.map((opt) => opt.text),
  };
  return schema;
}
