import { type RoleplayBlock } from '@lp-lib/game';
import type { BlockOutputsDesc } from '@lp-lib/game/src/block-outputs';

const outputSchema: BlockOutputsDesc = {
  result: {
    name: 'result',
    description: 'The result of the roleplay',
    schema: {
      type: 'enum',
      values: ['succeeded', 'failed'],
    },
  },
};

export function getOutputSchema(_block: RoleplayBlock) {
  return outputSchema;
}
