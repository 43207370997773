import { type CSSProperties, useEffect, useRef, useState } from 'react';

export function ScrollableContent(props: { children: React.ReactNode }) {
  const [hasScroll, setHasScroll] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const checkScroll = () => {
      const { scrollHeight, clientHeight, scrollTop } = container;
      const hasScrollContent = scrollHeight > clientHeight;
      const isScrolledToBottom = scrollHeight <= clientHeight + scrollTop + 1;
      setHasScroll(hasScrollContent && !isScrolledToBottom);
    };

    // check initially
    checkScroll();

    const resizeObserver = new ResizeObserver(checkScroll);
    resizeObserver.observe(container);
    container.addEventListener('scroll', checkScroll);
    return () => {
      resizeObserver.disconnect();
      container.removeEventListener('scroll', checkScroll);
    };
  }, []);

  return (
    <div className='relative w-full h-full flex flex-col'>
      <div
        ref={containerRef}
        className='w-full min-h-0 flex-1 overflow-y-auto scrollbar'
      >
        {props.children}
      </div>
      {hasScroll && (
        <div className='absolute bottom-0 left-0 right-0 flex justify-center'>
          <div
            className='px-2.5 py-1.5 bg-tertiary rounded-full text-black text-xs font-medium shadow-lg animate-fade-in-v2'
            style={
              {
                '--tw-fade-in-v2-duration': '400ms',
              } as CSSProperties
            }
          >
            Scroll for more content
          </div>
        </div>
      )}
    </div>
  );
}
