import { type MemoryMatchBlock } from '@lp-lib/game';
import type { BlockOutputsDesc } from '@lp-lib/game/src/block-outputs';

const outputSchema: BlockOutputsDesc = {
  mismatchCount: {
    name: 'mismatchCount',
    description: 'The number of times the user mismatched two items.',
    schema: {
      type: 'number',
    },
  },
};

export function getOutputSchema(_block: MemoryMatchBlock) {
  return outputSchema;
}
