import { useEffect, useState } from 'react';
import { useLatest } from 'react-use';
import { match } from 'ts-pattern';
import { proxy, useSnapshot } from 'valtio';

import {
  type DtoRoleplayHistory,
  type DtoSubmitRoleplayRatingRequest,
  type DtoTTSRenderRequest,
  EnumsRoleplayFailureCriterionResult,
  EnumsRoleplaySuccessCriterionResult,
  EnumsRoleplayTurnEndMode,
  EnumsTTSCacheControl,
  EnumsTTSRenderPolicy,
  type ModelsMediaAsset,
  type ModelsRoleplayHistoryFeedback,
  type ModelsRoleplayHistoryTranscript,
} from '@lp-lib/api-service-client/public';
import { MediaType, type RoleplayBlock } from '@lp-lib/game';
import { type BlockOutputsDesc } from '@lp-lib/game/src/block-outputs';
import { type Logger } from '@lp-lib/logger-base/src/LoggerBase';

import { useLiveAsyncCall } from '../../../../hooks/useAsyncCall';
import { apiService } from '../../../../services/api-service';
import { fromMediaDTO } from '../../../../utils/api-dto';
import { MediaPickPriorityHD, MediaUtils } from '../../../../utils/media';
import { markSnapshottable } from '../../../../utils/valtio';
import { FilledCheckIcon } from '../../../icons/CheckIcon';
import { CloseIcon } from '../../../icons/CloseIcon';
import { MinusIcon } from '../../../icons/MinusIcon';
import { RefreshIcon } from '../../../icons/RefreshIcon';
import { SpeakerIcon } from '../../../icons/SpeakerIcon';
import { StarIcon } from '../../../icons/StarIcon';
import { VoiceChatAPI } from '../../../VoiceChat/VoiceChatProvider';
import { getStingerPrompt } from '../../apis/StingerControl';
import { CommonButton } from '../../design/Button';
import {
  type BlockDependencies,
  type IBlockCtrl,
  type PlaygroundPlaybackProtocol,
} from '../../types';
import { MicRequired } from './MicRequired';
import { getOutputSchema } from './output';
import { makeRoleplayInstruction } from './Shared';

export class RoleplayBlockControlAPI implements IBlockCtrl {
  private _state = markSnapshottable(
    proxy<{
      status: 'init' | 'intro' | 'talking' | 'result' | 'feedback' | 'rating';
      startedAt: Nullable<Date>;
      endedAt: Nullable<Date>;
      isReplay: boolean;
      feedback: Nullable<ModelsRoleplayHistoryFeedback>;
      transcript: Nullable<ModelsRoleplayHistoryTranscript[]>;
      history: Nullable<DtoRoleplayHistory>;
    }>({
      status: 'init',
      startedAt: null,
      endedAt: null,
      isReplay: false,
      feedback: null,
      transcript: null,
      history: null,
    })
  );
  private delegate: Nullable<PlaygroundPlaybackProtocol>;
  private logger: Logger;
  private resolvedTTS: {
    stinger: Nullable<DtoTTSRenderRequest>;
  } = {
    stinger: null,
  };
  private resolvedInstructions: Nullable<string>;
  public VoiceChatAPI: VoiceChatAPI;
  private schema: BlockOutputsDesc;

  constructor(private block: RoleplayBlock, private deps: BlockDependencies) {
    this.logger = deps.getLogger('roleplay-block');
    this.VoiceChatAPI = new VoiceChatAPI();
    this.schema = getOutputSchema(block);
  }

  get state() {
    return this._state;
  }

  get voiceChatState() {
    return this.VoiceChatAPI.state;
  }

  async preload() {
    this.resolvedTTS.stinger = await this.stingerTTS();
    this.resolvedInstructions = makeRoleplayInstruction(this.block);
    this.initVoiceChat();
  }

  async initialize(preloaded: Promise<void>) {
    await preloaded;
  }

  setDelegate(delegate: PlaygroundPlaybackProtocol) {
    this.delegate = delegate;
  }

  async present() {
    try {
      await this.deps.stingerControl.playBlockIntro(
        this.block,
        this.resolvedTTS.stinger
      );
    } catch (e) {
      this.logger.error('failed to play stinger TTS', e);
    }

    this._state.status = 'intro';
  }

  async startTalking() {
    await this.VoiceChatAPI.connect();
    this._state.startedAt = new Date();
    this.VoiceChatAPI.client.createResponse();
    this._state.status = 'talking';
  }

  async showResult(feedback: ModelsRoleplayHistoryFeedback) {
    await this.endVoiceChat();
    this._state.endedAt = new Date();
    this._state.feedback = feedback;
    this._state.transcript = this.getTranscript();
    this._state.status = 'result';
    this.recordHistory();
    this.delegate?.blockDidOutput(this.schema.result, feedback.result);
  }

  async showFeedback() {
    this._state.status = 'feedback';
  }

  async replay() {
    this._state.isReplay = true;
    this._state.status = 'intro';
  }

  async showRating() {
    this._state.status = 'rating';
  }

  async end() {
    this.deps.sfxControl.play('instructionHoverReadyButton');
    await this.delegate?.blockDidEnd();
  }

  async reset() {
    await this.endVoiceChat();
  }

  async submitRating(req: DtoSubmitRoleplayRatingRequest) {
    if (!this._state.history) return;

    await apiService.block.submitRoleplayRating(this._state.history.id, req);
  }

  private initVoiceChat() {
    this.VoiceChatAPI.changeTurnEndMode(
      this.block.fields.turnEndMode ||
        EnumsRoleplayTurnEndMode.RoleplayTurnEndModeServerVad
    );
    this.VoiceChatAPI.updateInstructions(this.resolvedInstructions || '');
    if (this.block.fields.voice) {
      this.VoiceChatAPI.updateVoice(this.block.fields.voice);
    }

    this.VoiceChatAPI.client.addTool(
      {
        name: 'end_conversation',
        description: 'Ends the conversation with a feedback',
        parameters: {
          type: 'object',
          properties: {
            endReason: {
              type: 'string',
              description: 'Reason for ending the conversation',
            },
            result: {
              type: 'string',
              enum: ['succeeded', 'failed'],
              description: 'Result of the conversation',
            },
            failureCriteria: {
              type: 'array',
              description:
                'Failure criteria for the conversation, including undetermined criteria',
              items: {
                type: 'object',
                properties: {
                  description: {
                    type: 'string',
                    description: 'Description of the failure criteria',
                  },
                  result: {
                    type: 'string',
                    enum: ['triggered', 'avoided', 'undetermined'],
                    description: 'Result of the failure criteria',
                  },
                },
              },
              required: ['description', 'result'],
            },
            successCriteria: {
              type: 'array',
              description:
                'Success criteria for the conversation, including undetermined criteria',
              items: {
                type: 'object',
                properties: {
                  description: {
                    type: 'string',
                    description: 'Description of the success criteria',
                  },
                  result: {
                    type: 'string',
                    enum: ['met', 'failed', 'undetermined'],
                    description: 'Result of the success criteria',
                  },
                },
              },
              required: ['description', 'result'],
            },
          },
          required: [
            'endReason',
            'result',
            'failureCriteria',
            'successCriteria',
          ],
        },
      },
      async (
        feedback: ModelsRoleplayHistoryFeedback & { endReason: string }
      ) => {
        setTimeout(() => {
          this.showResult(feedback);
        }, 10);
        return { ok: true };
      }
    );

    this.VoiceChatAPI.init();
  }
  private async endVoiceChat() {
    if (this.VoiceChatAPI.state.status === 'disconnected') return;
    await this.VoiceChatAPI.disconnect();
  }

  private async recordHistory() {
    if (!this._state.feedback) return;
    const resp = await apiService.block.createRoleplayHistory({
      blockId: this.block.id,
      startedAt: this._state.startedAt?.toISOString() || '',
      endedAt: this._state.endedAt?.toISOString() || '',
      isReplay: this._state.isReplay,
      instructions: this.resolvedInstructions || '',
      feedback: this._state.feedback,
      transcript: this.getTranscript(),
    });
    this._state.history = resp.data.history;
  }

  getTranscript() {
    const transcripts: ModelsRoleplayHistoryTranscript[] = [];
    for (const i of this.VoiceChatAPI.state.items) {
      const item = this.VoiceChatAPI.getItem(i.id);
      if (!item) continue;
      if (item.role !== 'assistant' && item.role !== 'user') continue;

      transcripts.push({
        speaker: item.role,
        text: item.formatted.transcript || item.formatted.text || '',
      });
    }
    return transcripts;
  }
  private async stingerTTS(): Promise<Nullable<DtoTTSRenderRequest>> {
    if (!this.deps.stingerControl.shouldPreloadTTS(this.block)) return null;

    return this.makeTTSRenderRequest(
      getStingerPrompt(
        this.block.fields.scenarioPrompt,
        'Roleplay',
        'A roleplay conversation'
      )
    );
  }

  private async makeTTSRenderRequest(
    script: string
  ): Promise<Nullable<DtoTTSRenderRequest>> {
    const personalityId = this.block.fields.personalityId;
    if (!personalityId) return null;

    const resolved = await this.deps.commonVariableRegistry.render(script);
    return {
      script: resolved.script,
      personalityId,
      cacheControl: EnumsTTSCacheControl.TTSCacheControlShortLive,
      policy: EnumsTTSRenderPolicy.TTSRenderPolicyReadThrough,
    };
  }
}

function Media(props: {
  fullScreen: boolean;
  asset?: ModelsMediaAsset | null;
  onEnded?: () => void;
  nonVideoDelaySeconds?: number;
}) {
  const media = fromMediaDTO(props.asset?.media);
  const url = MediaUtils.PickMediaUrl(media, {
    priority: MediaPickPriorityHD,
  });
  const onEnded = useLatest(props.onEnded);

  useEffect(() => {
    if (media?.type === MediaType.Video) return;

    const timer = setTimeout(
      () => onEnded.current?.(),
      props.nonVideoDelaySeconds || 0
    );
    return () => clearTimeout(timer);
  }, [media?.type, onEnded, props.nonVideoDelaySeconds]);

  if (!media || !url) return null;

  return (
    <>
      {props.fullScreen ? (
        <div className='fixed inset-0'>
          {media.type === MediaType.Video && (
            <video
              playsInline
              autoPlay
              src={url}
              className='w-full h-full object-cover'
              onEnded={props.onEnded}
            />
          )}
          {media.type === MediaType.Image && (
            <img src={url} alt='' className='w-full h-full object-cover' />
          )}
        </div>
      ) : (
        <div className='w-full h-full flex justify-center items-center'>
          {media.type === MediaType.Video && (
            <video
              playsInline
              autoPlay
              src={url}
              className='rounded-2.5xl object-contain'
              onEnded={props.onEnded}
            />
          )}
          {media.type === MediaType.Image && (
            <img src={url} alt='' className='rounded-2.5xl object-contain' />
          )}
        </div>
      )}
    </>
  );
}

function Intro(props: { block: RoleplayBlock; ctrl: RoleplayBlockControlAPI }) {
  const [showButton, setShowButton] = useState(false);

  const {
    call,
    state: {
      state: { isRunning },
    },
  } = useLiveAsyncCall(async () => {
    await props.ctrl.startTalking();
  });

  return (
    <div className='w-full h-full flex flex-col'>
      <div className='w-full flex-1 min-h-0 p-5 sm:p-8 lg:p-10'>
        <Media
          fullScreen={props.block.fields.introMediaFullScreen}
          asset={props.block.fields.introMedia}
          nonVideoDelaySeconds={100}
          onEnded={() => setShowButton(true)}
        />
      </div>

      <footer
        className={`w-full flex items-center justify-center gap-2 p-3 pb-5 transition-opacity duration-300 ${
          showButton ? 'opacity-100' : 'opacity-0'
        }`}
      >
        <CommonButton
          variant='correct'
          onClick={() => call()}
          disabled={false}
          className='flex-none'
        >
          {isRunning
            ? 'Loading...'
            : props.block.fields.introButtonText || 'Start Conversation'}
        </CommonButton>
      </footer>
    </div>
  );
}

function hasAudioSignal(frequencies: Float32Array | null) {
  if (!frequencies) return false;
  const threshold = 0.1;
  const average =
    frequencies.reduce((sum, value) => sum + Math.abs(value), 0) /
    frequencies.length;
  return average > threshold;
}

type Speaker = 'user' | 'assistant';

function TalkingIndicator(props: {
  block: RoleplayBlock;
  ctrl: RoleplayBlockControlAPI;
}) {
  const [speaker, setSpeaker] = useState<Speaker | null>(null);
  const [captions, setCaptions] = useState<string>('');
  const isRecording = useSnapshot(props.ctrl.VoiceChatAPI.state).isRecording;

  useEffect(() => {
    let lastSpeakTime = new Date();

    const interval = setInterval(() => {
      const wavStreamPlayer = props.ctrl.VoiceChatAPI.getWavStreamPlayer();
      const wavRecorder = props.ctrl.VoiceChatAPI.getWavRecorder();

      let currentSpeaker: Speaker | null = null;
      if (
        wavStreamPlayer &&
        hasAudioSignal(wavStreamPlayer.getFrequencies('voice').values)
      ) {
        currentSpeaker = 'assistant';
        lastSpeakTime = new Date();
      } else if (
        wavRecorder &&
        hasAudioSignal(wavRecorder.getFrequencies('voice').values)
      ) {
        currentSpeaker = 'user';
        lastSpeakTime = new Date();
      }

      // If the speaker has changed or the last speaker was more than 1s ago, update the speaker
      if (
        currentSpeaker ||
        new Date().getTime() - lastSpeakTime.getTime() > 1000
      ) {
        setSpeaker(currentSpeaker);
      }

      if (currentSpeaker === 'assistant') {
        const latestItem =
          props.ctrl.VoiceChatAPI.state.items[
            props.ctrl.VoiceChatAPI.state.items.length - 1
          ];
        if (latestItem) {
          const item = props.ctrl.VoiceChatAPI.getItem(latestItem.id);
          if (item) {
            setCaptions(item.formatted.transcript || item.formatted.text || '');
          }
        }
      }
    }, 300);
    return () => clearInterval(interval);
  }, [props.ctrl.VoiceChatAPI]);

  if (speaker === 'assistant') {
    return (
      <div
        className='p-5 w-full font-bold text-white text-base lg:text-xl text-center'
        style={{
          fontFamily: 'PT Sans Caption',
          textShadow: '1.5px 1.5px 1.5px black',
        }}
      >
        {captions}
      </div>
    );
  }
  if (speaker === 'user' || isRecording) {
    return (
      <div className='w-full text-xl font-bold text-white flex justify-center items-center'>
        Listening...
      </div>
    );
  }
  return (
    <div className='w-full text-xl font-bold text-green-001 flex justify-center items-center gap-2 animate-pulse'>
      <SpeakerIcon className='w-5 h-5 fill-current' />
      Start talking...
    </div>
  );
}

function Talking(props: {
  block: RoleplayBlock;
  ctrl: RoleplayBlockControlAPI;
}) {
  const [closing, setClosing] = useState(false);
  const [transition, setTransition] = useState(false);

  const handleEndConversation = () => {
    setClosing(true);
    // TODO: handle the case when the system hangs
    props.ctrl.VoiceChatAPI.client.sendUserMessageContent([
      {
        type: 'input_text',
        text: 'User has requested to end the conversation. Please evaluate our conversation and end it without asking for confirmation.',
      },
    ]);
  };

  const startTransition = () => {
    setTransition(true);
    // in case of mistakely clicking the button
    setTimeout(() => setTransition(false), 300);
  };

  const isRecording = useSnapshot(props.ctrl.VoiceChatAPI.state).isRecording;

  return (
    <div className='w-full h-full flex flex-col'>
      <div className='w-full flex-1 min-h-0 p-5 sm:p-8 lg:p-10'>
        <Media
          fullScreen={props.block.fields.playingMediaFullScreen}
          asset={props.block.fields.playingMedia}
        />
      </div>

      <div className='relative w-full h-0'>
        <div className='absolute bottom-3 w-full'>
          <TalkingIndicator block={props.block} ctrl={props.ctrl} />
        </div>
      </div>

      {props.block.fields.turnEndMode ===
      EnumsRoleplayTurnEndMode.RoleplayTurnEndModePushToTalk ? (
        <footer
          className={`w-full p-3 pb-5 flex items-center justify-center gap-2`}
        >
          <CommonButton
            variant='gray'
            onClick={handleEndConversation}
            className='flex-none'
            styles={{ size: 'h-full' }}
            style={{
              aspectRatio: '1/1',
            }}
          >
            <CloseIcon className='w-4 h-4 fill-current' />
          </CommonButton>

          {isRecording ? (
            <CommonButton
              variant='gray'
              onClick={() => {
                startTransition();
                props.ctrl.VoiceChatAPI.stopRecording();
              }}
              disabled={transition}
              className='flex-none'
            >
              Done
            </CommonButton>
          ) : (
            <CommonButton
              variant='correct'
              onClick={() => {
                startTransition();
                props.ctrl.VoiceChatAPI.startRecording();
              }}
              disabled={transition}
              className='flex-none'
            >
              Talk
            </CommonButton>
          )}
        </footer>
      ) : (
        <footer className='w-full flex items-center justify-center gap-2 p-3 pb-5'>
          <CommonButton
            variant='incorrect'
            onClick={handleEndConversation}
            disabled={closing}
            className='flex-none'
          >
            {props.block.fields.playingButtonText || 'End Conversation'}
          </CommonButton>
        </footer>
      )}
    </div>
  );
}

function Result(props: {
  block: RoleplayBlock;
  ctrl: RoleplayBlockControlAPI;
}) {
  return (
    <div className='w-full h-full flex flex-col'>
      <div className='w-full flex-1 min-h-0 p-5 sm:p-8 lg:p-10'>
        <Media
          fullScreen={
            props.ctrl.state.feedback?.result === 'succeeded'
              ? props.block.fields.successMediaFullScreen
              : props.block.fields.failureMediaFullScreen
          }
          asset={
            props.ctrl.state.feedback?.result === 'succeeded'
              ? props.block.fields.successMedia
              : props.block.fields.failureMedia
          }
          nonVideoDelaySeconds={4000}
          onEnded={() => props.ctrl.showFeedback()}
        />
      </div>
    </div>
  );
}

function Feedback(props: {
  block: RoleplayBlock;
  ctrl: RoleplayBlockControlAPI;
}) {
  const result = props.ctrl.state.feedback;

  const downloadTranscript = () => {
    const transcripts = props.ctrl.getTranscript();
    const blob = new Blob(
      [transcripts.map((t) => `${t.speaker}: ${t.text}`).join('\n\n')],
      {
        type: 'text/plain',
      }
    );
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'transcript.txt';
    a.click();
  };

  return (
    <div className='w-full h-full flex flex-col justify-end items-center text-white'>
      <main
        className='w-full flex-1 px-8 py-5 flex flex-col items-center overflow-auto scrollbar'
        style={{
          maskImage:
            'linear-gradient(180deg, #D9D9D9 95.71%, rgba(115, 115, 115, 0.00) 100%)',
        }}
      >
        <div className='text-base font-bold'>Feedback</div>
        <div
          className={`mt-3 text-2xl font-bold text-center ${
            props.ctrl.state.feedback?.result === 'succeeded'
              ? 'text-green-001'
              : 'text-red-001'
          }`}
        >
          {props.ctrl.state.feedback?.result === 'succeeded'
            ? 'Succeeded!'
            : 'Failed!'}
        </div>

        <button
          type='button'
          className='mt-3 text-sm text-primary underline'
          onClick={downloadTranscript}
        >
          Download Transcript
        </button>

        {result?.successCriteria && result?.successCriteria.length > 0 && (
          <div className='w-full mt-5'>
            <div className='text-sm font-bold'>Looking for:</div>
            <ul className='mt-3 flex flex-col gap-3'>
              {result?.successCriteria.map((c, i) => (
                <li key={i} className='flex gap-2'>
                  <p className='flex-none w-4 h-6 flex items-center justify-center'>
                    {match(c.result)
                      .with(
                        EnumsRoleplaySuccessCriterionResult.RoleplaySuccessCriterionResultMet,
                        () => (
                          <FilledCheckIcon className='w-4 h-4 fill-current text-green-001' />
                        )
                      )
                      .with(
                        EnumsRoleplaySuccessCriterionResult.RoleplaySuccessCriterionResultFailed,
                        () => (
                          <CloseIcon className='w-3 h-3 fill-current text-red-001' />
                        )
                      )
                      .with(
                        EnumsRoleplaySuccessCriterionResult.RoleplaySuccessCriterionResultUndetermined,
                        () => (
                          <MinusIcon className='w-3 h-3 fill-current text-tertiary' />
                        )
                      )
                      .exhaustive()}
                  </p>
                  <p className='text-base'>{c.description}</p>
                </li>
              ))}
            </ul>
          </div>
        )}
        {result?.failureCriteria && result?.failureCriteria.length > 0 && (
          <div className='w-full mt-5'>
            <div className='text-sm font-bold'>Avoiding:</div>
            <ul className='mt-3 flex flex-col gap-3'>
              {result?.failureCriteria.map((c, i) => (
                <li key={i} className='flex gap-2'>
                  <p className='flex-none w-4 h-6 flex items-center justify-center'>
                    {match(c.result)
                      .with(
                        EnumsRoleplayFailureCriterionResult.RoleplayFailureCriterionResultTriggered,
                        () => (
                          <CloseIcon className='w-3 h-3 fill-current text-red-001' />
                        )
                      )
                      .with(
                        EnumsRoleplayFailureCriterionResult.RoleplayFailureCriterionResultAvoided,
                        () => (
                          <FilledCheckIcon className='w-4 h-4 fill-current text-green-001' />
                        )
                      )
                      .with(
                        EnumsRoleplayFailureCriterionResult.RoleplayFailureCriterionResultUndetermined,
                        () => (
                          <MinusIcon className='w-3 h-3 fill-current text-tertiary' />
                        )
                      )
                      .exhaustive()}
                  </p>
                  <p>{c.description}</p>
                </li>
              ))}
            </ul>
          </div>
        )}
      </main>
      <footer className='w-full flex flex-col items-center'>
        {props.ctrl.state.feedback?.result === 'failed' && (
          <div className='text-xs text-red-006'>
            Room for improvement! See above.
          </div>
        )}

        <div className='w-full flex justify-center items-center gap-2 p-3 pb-5'>
          <CommonButton
            variant='gray'
            onClick={() => {
              props.ctrl.replay();
            }}
            className='flex-none'
            styles={{ size: 'h-full' }}
            style={{
              aspectRatio: '1/1',
            }}
          >
            <RefreshIcon className='w-4 h-4 fill-current' />
          </CommonButton>
          <CommonButton
            variant={
              props.ctrl.state.feedback?.result === 'succeeded'
                ? 'correct'
                : 'incorrect'
            }
            onClick={() => props.ctrl.showRating()}
            disabled={false}
            className='flex-none'
          >
            Got it!
          </CommonButton>
        </div>
      </footer>
    </div>
  );
}

function Rating(props: {
  block: RoleplayBlock;
  ctrl: RoleplayBlockControlAPI;
}) {
  const [score, setScore] = useState(0);
  const [comment, setComment] = useState('');

  const handleTextareaInput = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const textarea = event.target;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
    setComment(textarea.value);
  };

  const {
    call,
    state: {
      state: { isRunning },
    },
  } = useLiveAsyncCall(async () => {
    if (score > 0) {
      await props.ctrl.submitRating({ rating: { score, comment } });
    }
    await props.ctrl.end();
  });

  return (
    <div className='w-full h-full flex flex-col gap-2 justify-between items-center p-5 text-white'>
      <div className='w-full flex-1 overflow-auto scrollbar flex flex-col gap-4 items-center justify-center'>
        <h2 className='text-2xl font-bold text-center'>
          How was your roleplay experience?
        </h2>

        <div className='flex items-center gap-1'>
          {[1, 2, 3, 4, 5].map((i) => (
            <button
              key={i}
              onClick={() => setScore(i)}
              type='button'
              className={`transition-colors ${
                i <= score ? 'text-tertiary' : 'text-white'
              }`}
            >
              <StarIcon className='w-10 h-10 fill-current' />
            </button>
          ))}
        </div>

        {score > 0 && (
          <textarea
            autoFocus
            value={comment}
            onChange={handleTextareaInput}
            placeholder='Tell us what you liked or what could be improved (optional)'
            className='w-full min-h-20 field p-2 m-0 scrollbar-hide'
          />
        )}
      </div>

      <div className='flex justify-center w-full'>
        <CommonButton
          variant={score > 0 ? 'correct' : 'gray'}
          onClick={() => call()}
          disabled={isRunning}
          className='px-8'
        >
          {score > 0 ? 'Submit' : 'Skip'}
        </CommonButton>
      </div>
    </div>
  );
}

export function RoleplayBlockPlayground(props: {
  block: RoleplayBlock;
  ctrl: RoleplayBlockControlAPI;
}) {
  const status = useSnapshot(props.ctrl.state).status;

  useEffect(() => {
    if (status !== 'init') return;

    props.ctrl.present();
  }, [status, props.ctrl]);

  useEffect(() => {
    return () => {
      props.ctrl.reset();
    };
  }, [props.ctrl]);

  if (status === 'init') return null;
  return (
    <MicRequired>
      {match(status)
        .with('intro', () => <Intro block={props.block} ctrl={props.ctrl} />)
        .with('talking', () => (
          <Talking block={props.block} ctrl={props.ctrl} />
        ))
        .with('result', () => <Result block={props.block} ctrl={props.ctrl} />)
        .with('feedback', () => (
          <Feedback block={props.block} ctrl={props.ctrl} />
        ))
        .with('rating', () => <Rating block={props.block} ctrl={props.ctrl} />)
        .exhaustive()}
    </MicRequired>
  );
}
