import { useCallback, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { proxy } from 'valtio';

import {
  type DtoTTSRenderRequest,
  EnumsTTSCacheControl,
  EnumsTTSRenderPolicy,
} from '@lp-lib/api-service-client/public';
import {
  Delimited,
  type QuestionBlock,
  QuestionBlockAnswerGrade,
  type QuestionBlockAnswerGradeModel,
} from '@lp-lib/game';
import { type BlockOutputsDesc } from '@lp-lib/game/src/block-outputs';
import { type Logger } from '@lp-lib/logger-base';

import { getFeatureQueryParamNumber } from '../../../../hooks/useFeatureQueryParam';
import { apiService } from '../../../../services/api-service';
import { sleep } from '../../../../utils/common';
import { markSnapshottable, useSnapshot } from '../../../../utils/valtio';
import {
  lvoLocalCacheWarm,
  LVOLocalPlayer,
} from '../../../VoiceOver/LocalLocalizedVoiceOvers';
import { getStingerPrompt } from '../../apis/StingerControl';
import { CommonButton } from '../../design/Button';
import { CorrectAnimationWithLayout } from '../../design/CorrectAnimation';
import { CommonInput } from '../../design/Input';
import {
  type BlockDependencies,
  type IBlockCtrl,
  type PlaygroundPlaybackProtocol,
} from '../../types';
import { getOutputSchema } from './outputs';

function getEvaluationPrompt(
  question: string,
  answer: string,
  evaluation: string
) {
  return `
\`\`\`openai { "model": "gpt-4o-mini", "temperature": 1, "maxTokens": 1024 }
You are a scriptwriter for an online platform called Luna Park. Your only job is to write a script that informs a user if they answered correctly or incorrectly. I will give you the expected answer, the user's submitted answer, and how we have evaluated it.

Your script should be short and creative.

When the evaluation is incorrect, use only the provided question and answer to restate what the user missed. Do not use any other information or knowledge for this script.  

When the evaluation is correct, your script can be short and does not need to restate the answer.
Output only the script, with nothing before or after.

<example>
Question: Why is it challenging to host for an unseen audience?
Correct Answer: You can't adjust based on immediate feedback
Evaluation: incorrect
Output: Incorrect. One challenge for a host is they cannot adjust based on immediate feedback.
</example>

Output only the script, with nothing before or after. 

Question: ${question}
Correct Answer: ${answer}
Evaluation: ${evaluation}
\`\`\`
`.trim();
}

type State = {
  status: 'init' | 'present' | 'play' | 'grade' | 'inform' | 'complete';
  questionVisibility: 'visible' | 'hidden';
  inputVisibility: 'visible' | 'hidden';
  cta: 'submit' | 'complete';
  submission?: string;
  result?: 'correct' | 'incorrect' | null;
};

export class QuestionBlockControlAPI implements IBlockCtrl {
  private _state = markSnapshottable(
    proxy<State>({
      status: 'init',
      questionVisibility: 'hidden',
      inputVisibility: 'hidden',
      cta: 'submit',
    })
  );
  private resolvedTTS: {
    stinger: Nullable<DtoTTSRenderRequest>;
    question: Nullable<DtoTTSRenderRequest>;
    correct: Nullable<DtoTTSRenderRequest>;
    incorrect: Nullable<DtoTTSRenderRequest>;
  } = {
    stinger: null,
    question: null,
    correct: null,
    incorrect: null,
  };
  private delegate: Nullable<PlaygroundPlaybackProtocol>;
  private logger: Logger;
  private grader: Grader;
  private schema: BlockOutputsDesc;
  private readonly willGrade: boolean;

  constructor(private block: QuestionBlock, private deps: BlockDependencies) {
    this.logger = deps.getLogger('question-block');
    this.grader = GraderPipeline.FromBlock(block);
    this.schema = getOutputSchema(block);
    this.willGrade = block.fields.points !== 0;
  }

  get state() {
    return this._state;
  }

  async preload() {
    if (!this.block.fields.personalityId) return;

    this.resolvedTTS.stinger = await this.stingerTTS();
    this.resolvedTTS.question = await this.questionTTS();
    if (this.willGrade) {
      this.resolvedTTS.correct = await this.correctTTS();
      this.resolvedTTS.incorrect = await this.incorrectTTS();
      lvoLocalCacheWarm(this.resolvedTTS.correct);
      lvoLocalCacheWarm(this.resolvedTTS.incorrect);
    }
    await lvoLocalCacheWarm(this.resolvedTTS.stinger);
    await lvoLocalCacheWarm(this.resolvedTTS.question);
  }

  async initialize(preloaded: Promise<void>) {
    await preloaded;
  }

  setDelegate(delegate: PlaygroundPlaybackProtocol) {
    this.delegate = delegate;
  }

  async present() {
    try {
      await this.deps.stingerControl.playBlockIntro(
        this.block,
        this.resolvedTTS.stinger
      );
    } catch (e) {
      this.logger.error('failed to play stinger TTS', e);
    }

    try {
      const player = new LVOLocalPlayer(this.resolvedTTS.question);
      const info = await player.playFromPool();
      await info?.trackStarted;
      this._state.status = 'present';
      this._state.questionVisibility = 'visible';
      await info?.trackEnded;
    } catch (e) {
      this.logger.error(
        'failed to play question TTS, falling back to silence',
        e
      );
      this._state.status = 'present';
      this._state.questionVisibility = 'visible';
      await sleep(3000);
    }
    this._state.status = 'play';
    this._state.inputVisibility = 'visible';
  }

  async submitAnswer(submission: string) {
    this.deps.sfxControl.play('instructionHoverReadyButton');
    this._state.submission = submission;
    this.delegate?.blockDidOutput(this.schema.submission, submission);

    if (!this.willGrade) {
      this._state.status = 'complete';
      this._state.cta = 'complete';
      return this.delegate?.blockDidEnd();
    }

    this._state.status = 'grade';
    const grade = await this.grader.grade(submission);
    const result =
      grade.grade === QuestionBlockAnswerGrade.CORRECT
        ? 'correct'
        : 'incorrect';
    this.delegate?.blockDidOutput(this.schema.grade, result);

    this._state.result = result;
    this._state.status = 'inform';
    this._state.cta = 'complete';
    this.deps.sfxControl.play(
      result === 'correct' ? 'rapidCorrect' : 'rapidWrong'
    );
    try {
      const player = new LVOLocalPlayer(
        result === 'correct'
          ? this.resolvedTTS.correct
          : this.resolvedTTS.incorrect
      );
      const info = await player.playFromPool();
      await info?.trackEnded;
    } catch (e) {
      this.logger.error('failed to play result TTS', e);
    }
    this._state.status = 'complete';
  }

  async end() {
    this.deps.sfxControl.play('instructionHoverReadyButton');
    await this.delegate?.blockDidEnd();
  }

  private async stingerTTS(): Promise<Nullable<DtoTTSRenderRequest>> {
    if (!this.deps.stingerControl.shouldPreloadTTS(this.block)) return null;

    return this.makeTTSRenderRequest(
      getStingerPrompt(
        this.block.fields.question,
        'Quick Q',
        'A short open-ended question.'
      )
    );
  }

  private async questionTTS() {
    return this.makeTTSRenderRequest(this.block.fields.question);
  }

  private async correctTTS() {
    return this.makeTTSRenderRequest(
      getEvaluationPrompt(
        this.block.fields.question,
        this.block.fields.answer,
        'correct'
      )
    );
  }

  private async incorrectTTS() {
    return this.makeTTSRenderRequest(
      getEvaluationPrompt(
        this.block.fields.question,
        this.block.fields.answer,
        'incorrect'
      )
    );
  }

  private async makeTTSRenderRequest(
    script: string
  ): Promise<Nullable<DtoTTSRenderRequest>> {
    const personalityId = this.block.fields.personalityId;
    if (!personalityId) return null;

    const resolved = await this.deps.commonVariableRegistry.render(script);
    return {
      script: resolved.script,
      personalityId,
      cacheControl: EnumsTTSCacheControl.TTSCacheControlShortLive,
      policy: EnumsTTSRenderPolicy.TTSRenderPolicyReadThrough,
    };
  }
}

export function QuestionBlockPlayground(props: {
  block: QuestionBlock;
  ctrl: QuestionBlockControlAPI;
}) {
  const { question, answer } = props.block.fields;
  const {
    status,
    questionVisibility,
    inputVisibility,
    submission,
    result,
    cta,
  } = useSnapshot(props.ctrl.state);
  const [submissionValue, setSubmissionValue] = useState('');
  useEffectOnce(() => {
    props.ctrl.present();
  });

  const handleSubmit = useCallback(() => {
    props.ctrl.submitAnswer(submissionValue);
  }, [props.ctrl, submissionValue]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        handleSubmit();
      }
    },
    [handleSubmit]
  );

  return (
    <div className='relative w-full h-full min-h-0 flex flex-col'>
      <main className='w-full flex-1 min-h-0 px-10 flex flex-col justify-center gap-5 sm:gap-10'>
        <div
          className={`
            text-white text-center break-words
            text-base sm:text-xl lg:text-2xl
            transition-opacity duration-500 ${
              questionVisibility === 'visible' ? 'opacity-100' : 'opacity-0'
            }
         `}
        >
          {question}
        </div>

        <div
          className={`w-full transition-opacity duration-500 ${
            inputVisibility === 'visible' ? 'opacity-100' : 'opacity-0'
          }`}
        >
          <CommonInput
            type='text'
            placeholder='Type your answer here'
            value={submissionValue}
            onChange={(e) => setSubmissionValue(e.target.value)}
            disabled={submission !== undefined}
            onKeyDown={handleKeyDown}
            variant={result ?? 'brand'}
          />
          <div
            className={`pt-2 transition-opacity duration-500 ${
              result === 'incorrect' ? 'opacity-100' : 'opacity-0'
            }`}
          >
            <div className='text-center text-green-001 font-bold text-xs'>
              Correct answer
            </div>
            <div className='text-center text-green-001 text-base'>{answer}</div>
          </div>
        </div>
      </main>

      <footer
        className={`'w-full flex flex-col items-center gap-2 px-3 pt-3 pb-5 transition-opacity duration-500 relative ${
          inputVisibility === 'visible' ? 'opacity-100' : 'opacity-0'
        }`}
      >
        <div className='w-full h-5 flex items-center justify-center text-xs text-black text-opacity-80 text-center'>
          {result === 'correct' ? (
            <span className='text-green-001'>
              <strong>Correct!</strong> Nice job!
            </span>
          ) : result === 'incorrect' ? (
            <span className='text-red-006'>
              <strong>Incorrect!</strong> See correct answer above.
            </span>
          ) : null}
        </div>
        {cta === 'submit' ? (
          <CommonButton
            variant='brand'
            onClick={handleSubmit}
            disabled={status !== 'play' || submissionValue === ''}
          >
            Submit
          </CommonButton>
        ) : (
          <CommonButton
            variant={result ?? 'brand'}
            onClick={() => props.ctrl.end()}
            disabled={status !== 'complete'}
          >
            Continue
          </CommonButton>
        )}
        {result === 'correct' && (
          <CorrectAnimationWithLayout onEnd={() => void 0} />
        )}
      </footer>
    </div>
  );
}

// note(falcon): cloned from the old block, but we probably want to avoid cross dependencies here.
type GradeResult = {
  model?: QuestionBlockAnswerGradeModel;
  grade: QuestionBlockAnswerGrade;
  timeMs: number;
  error?: unknown;
  cached?: boolean;
};

interface Grader {
  grade(submission: string): Promise<GradeResult>;
}

class SimpleGrader implements Grader {
  constructor(private correctAnswers: string[]) {}

  async grade(submission: string): Promise<GradeResult> {
    const trimmedAnswer = submission.trim().toLowerCase();
    if (this.correctAnswers.some((correct) => correct === trimmedAnswer)) {
      return {
        model: 'Preset',
        grade: QuestionBlockAnswerGrade.CORRECT,
        timeMs: 0,
      };
    }
    return {
      model: 'Preset',
      grade: QuestionBlockAnswerGrade.NONE,
      timeMs: 0,
    };
  }
}

class AIGrader implements Grader {
  constructor(
    private model: QuestionBlockAnswerGradeModel,
    private temperature: number,
    private correctAnswers: string[]
  ) {}

  async grade(submission: string): Promise<GradeResult> {
    const trimmedAnswer = submission.trim().toLowerCase();
    const t1 = Date.now();
    const req = {
      model: this.model,
      temperature: this.temperature,
      correctAnswers: this.correctAnswers,
      userSubmission: trimmedAnswer,
    };
    try {
      const resp = await apiService.aiGrade.gradeSubmission(req);
      return {
        model: this.model,
        grade: resp.data.evaluation
          ? QuestionBlockAnswerGrade.CORRECT
          : QuestionBlockAnswerGrade.NONE,
        timeMs: Date.now() - t1,
        error: null,
      };
    } catch (error) {
      // TODO(falcon): logging.
      return {
        model: this.model,
        grade: QuestionBlockAnswerGrade.NONE,
        timeMs: Date.now() - t1,
        error,
      };
    }
  }
}

class GraderPipeline implements Grader {
  constructor(private graders: Grader[]) {}

  static FromBlock(block: QuestionBlock) {
    const correctAnswers = [
      block.fields.answer,
      ...new Delimited().parse(block.fields.additionalAnswers || ''),
    ]
      .map((a) => a?.trim().toLowerCase())
      .filter(Boolean);
    const temperature = getFeatureQueryParamNumber(
      'question-block-ai-grade-temperature',
      true
    );
    const graders: Grader[] = [];
    graders.push(new SimpleGrader(correctAnswers));
    graders.push(
      new AIGrader('GPTv3', temperature, correctAnswers.slice(0, 3))
    );
    graders.push(
      new AIGrader('GPTv4', temperature, correctAnswers.slice(0, 3))
    );
    return new GraderPipeline(graders);
  }

  async grade(submission: string): Promise<GradeResult> {
    const trimmedAnswer = submission.trim().toLowerCase();
    const results: GradeResult[] = [];
    for (const grader of this.graders) {
      const r = await grader.grade(trimmedAnswer);
      results.push(r);
      if (r.grade !== QuestionBlockAnswerGrade.NONE) {
        break;
      }
    }
    return (
      results[results.length - 1] ?? {
        model: 'Preset',
        grade: QuestionBlockAnswerGrade.NONE,
      }
    );
  }
}
