import {
  type CSSProperties,
  forwardRef,
  type InputHTMLAttributes,
  type Ref,
  useMemo,
} from 'react';

import { useOrgBrandColor } from '../../VenueOrgLogoAverageColor/useOrgBrandColor';

export type RawInputStyles = Partial<{
  text: string;
  size: string;
  spacing: string;
  bg: string;
  border: string;
  brandColor: string;
}>;

export const RawInput = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement> & {
    styles?: RawInputStyles;
  }
>((props, ref) => {
  const { styles, ...rest } = props;
  return (
    <input
      ref={ref}
      {...rest}
      style={
        {
          ...props.style,
          '--brand-color': styles?.brandColor,
        } as CSSProperties
      }
      className={`
        appearance-none focus:outline-none
        transition-colors
        ${styles?.size ?? 'w-full'}
        ${styles?.spacing ?? 'px-5 py-2'}
        ${
          styles?.text ??
          'text-center text-white text-base sm:text-xl lg:text-2xl font-bold placeholder-italic placeholder-icon-gray'
        }
        ${styles?.bg ?? 'bg-transparent'}
        ${
          styles?.border ??
          'border-b focus:border-[color:var(--brand-color)] rounded-none'
        }
        ${props.className || ''}
      `}
    />
  );
});

export type InputVariants = Record<string, RawInputStyles>;

export type InputProps<T extends InputVariants> = {
  variant: keyof T;
  variants: T;
  ref?: Ref<HTMLInputElement>;
  styles?: RawInputStyles;
} & InputHTMLAttributes<HTMLInputElement>;

export function Input<T extends InputVariants = InputVariants>(
  props: InputProps<T>
) {
  const { variant, variants, styles, ...rest } = props;

  return (
    <RawInput
      ref={props.ref}
      styles={{ ...styles, ...variants[variant] }}
      {...rest}
    />
  );
}

export type CommonInputVariant = 'correct' | 'incorrect' | 'brand';

const commonVariants: Record<CommonInputVariant, RawInputStyles> = {
  correct: {
    border: 'border-b border-green-001 rounded-none',
  },
  incorrect: {
    border: 'border-b border-red-001 rounded-none',
  },
  brand: {
    brandColor: '#FBB707',
  },
} as const;

export function CommonInput(
  props: Omit<InputProps<typeof commonVariants>, 'variants'>
) {
  const { color } = useOrgBrandColor();

  const variants = useMemo(
    () => ({
      ...commonVariants,
      brand: color
        ? {
            ...commonVariants.brand,
            brandColor: color,
          }
        : commonVariants.brand,
    }),
    [color]
  );

  return <Input<typeof commonVariants> {...props} variants={variants} />;
}
