import { Transition } from '@tailwindui/react';
import { useEffect, useRef, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { proxy, useSnapshot } from 'valtio';

import {
  type DtoTTSRenderRequest,
  EnumsTTSCacheControl,
  EnumsTTSRenderPolicy,
} from '@lp-lib/api-service-client/public';
import { getStaticAssetPath } from '@lp-lib/email-templates/src/utils';
import { type DrawToWinBlock } from '@lp-lib/game';
import { type Logger } from '@lp-lib/logger-base';

import { useLiveAsyncCall } from '../../../../hooks/useAsyncCall';
import { sleep } from '../../../../utils/common';
import {
  markSnapshottable,
  type ValtioSnapshottable,
} from '../../../../utils/valtio';
import { UndoIcon2 } from '../../../icons/UndoIcon';
import { Loading } from '../../../Loading';
import {
  lvoLocalCacheWarm,
  LVOLocalPlayer,
} from '../../../VoiceOver/LocalLocalizedVoiceOvers';
import { getStingerPrompt } from '../../apis/StingerControl';
import { CommonButton } from '../../design/Button';
import { CorrectAnimationWithLayout } from '../../design/CorrectAnimation';
import {
  type BlockDependencies,
  type IBlockCtrl,
  type PlaygroundPlaybackProtocol,
} from '../../types';
import { DrawingStageManager } from './DrawingStageManager';
import {
  BlurBackground,
  BrushIcon,
  BrushSizePicker,
  EraserIcon,
  MarkupToolButton,
} from './Shared';

type State = {
  status: 'init' | 'present' | 'play' | 'grade' | 'inform' | 'complete';
  visibility: 'visible' | 'hidden';
  cta: 'submit' | 'complete';
  result?: 'correct' | 'incorrect' | null;
  grade?: {
    correct: number;
    missed: number;
    wrong: number;
  };
};

export class DrawToWinBlockControlAPI implements IBlockCtrl {
  private _state = markSnapshottable(
    proxy<State>({
      status: 'init',
      visibility: 'hidden',
      cta: 'submit',
    })
  );
  private resolvedTTS: {
    stinger: Nullable<DtoTTSRenderRequest>;
    question: Nullable<DtoTTSRenderRequest>;
    completion: Nullable<DtoTTSRenderRequest>;
  } = {
    stinger: null,
    question: null,
    completion: null,
  };
  private delegate: Nullable<PlaygroundPlaybackProtocol>;
  private logger: Logger;

  constructor(private block: DrawToWinBlock, readonly deps: BlockDependencies) {
    this.logger = deps.getLogger('draw-to-win-block');
  }

  get state() {
    return this._state;
  }

  async preload() {
    if (!this.block.fields.personalityId) return;

    this.resolvedTTS.stinger = await this.stingerTTS();
    this.resolvedTTS.question = await this.questionTTS();

    await lvoLocalCacheWarm(this.resolvedTTS.stinger);
    await lvoLocalCacheWarm(this.resolvedTTS.question);
  }
  async initialize(preloaded: Promise<void>) {
    await preloaded;
  }
  async present() {
    try {
      await this.deps.stingerControl.playBlockIntro(
        this.block,
        this.resolvedTTS.stinger
      );
    } catch (e) {
      this.logger.error('failed to play stinger TTS', e);
    }

    try {
      const player = new LVOLocalPlayer(this.resolvedTTS.question);
      const info = await player.playFromPool();
      await info?.trackStarted;
      this._state.status = 'present';
      this._state.visibility = 'visible';
      await info?.trackEnded;
    } catch (e) {
      this.logger.error(
        'failed to play question TTS, falling back to silence',
        e
      );
      this._state.status = 'present';
      this._state.visibility = 'visible';
      await sleep(3000);
    }

    this._state.status = 'play';
  }

  setDelegate(delegate: PlaygroundPlaybackProtocol) {
    this.delegate = delegate;
  }

  async grade(grader: () => NonNullable<State['grade']>) {
    this.deps.sfxControl.play('instructionHoverReadyButton');
    this._state.status = 'grade';
    this._state.grade = grader();
    const correctThreshold = this.block.fields.matchTool?.correctThreshold ?? 0;
    const missedThreshold =
      this.block.fields.matchTool?.missedThreshold ?? Number.POSITIVE_INFINITY;
    const wrongThreshold =
      this.block.fields.matchTool?.wrongThreshold ?? Number.POSITIVE_INFINITY;

    try {
      this.resolvedTTS.completion = await this.makeTTSRenderRequest(
        getCompletionPrompt({
          question: this.block.fields.question,
          correct: {
            target: correctThreshold.toFixed(2),
            user: this._state.grade.correct.toFixed(2),
          },
          missed: {
            target: missedThreshold.toFixed(2),
            user: this._state.grade.missed.toFixed(2),
          },
          wrong: {
            target: wrongThreshold.toFixed(2),
            user: this._state.grade.wrong.toFixed(2),
          },
        })
      );
      await lvoLocalCacheWarm(this.resolvedTTS.completion);
    } catch (error) {
      this.logger.error('failed to resolve completion TTS', error);
    }

    this._state.result =
      this._state.grade.correct >= correctThreshold &&
      this._state.grade.missed < missedThreshold &&
      this._state.grade.wrong < wrongThreshold
        ? 'correct'
        : 'incorrect';
    this._state.status = 'inform';
    this._state.cta = 'complete';

    if (this.resolvedTTS.completion) {
      try {
        const player = new LVOLocalPlayer(this.resolvedTTS.completion);
        const info = await player.playFromPool();
        await info?.trackEnded;
      } catch (e) {
        this.logger.error('failed to play completion TTS', e);
      }
    }

    this._state.status = 'complete';
  }

  async end() {
    await this.delegate?.blockDidEnd();
  }

  private async stingerTTS(): Promise<Nullable<DtoTTSRenderRequest>> {
    if (!this.deps.stingerControl.shouldPreloadTTS(this.block)) return null;

    return this.makeTTSRenderRequest(
      getStingerPrompt(
        this.block.fields.question,
        'Tap and drag to draw',
        'Draw to win.'
      )
    );
  }

  private async questionTTS() {
    return this.makeTTSRenderRequest(this.block.fields.question);
  }

  private async makeTTSRenderRequest(
    script: string
  ): Promise<Nullable<DtoTTSRenderRequest>> {
    const personalityId = this.block.fields.personalityId;
    if (!personalityId) return null;

    const resolved = await this.deps.commonVariableRegistry.render(script);
    return {
      script: resolved.script,
      personalityId,
      cacheControl: EnumsTTSCacheControl.TTSCacheControlShortLive,
      policy: EnumsTTSRenderPolicy.TTSRenderPolicyReadThrough,
    };
  }
}

function Toolbar(props: { stageman: DrawingStageManager; disabled?: boolean }) {
  const { stageman } = props;
  const state = useSnapshot(stageman.state);
  return (
    <div className='flex items-center justify-between'>
      <div className='flex items-center text-white gap-1'>
        <div className='flex items-center gap-2'>
          <MarkupToolButton
            label='Eraser'
            accessory={<EraserIcon className='w-6 h-6' />}
            onClick={() => stageman.toggleBrushMode('eraser')}
            active={state.brushMode === 'eraser'}
            disabled={props.disabled}
            hideLabel
          />
          <div className='relative'>
            <MarkupToolButton
              label='Brush'
              accessory={<BrushIcon className='w-6 h-6' />}
              onClick={() =>
                stageman.toggleBrushMode(
                  state.brushMode === 'brush' ? false : 'brush'
                )
              }
              active={state.brushMode === 'brush'}
              disabled={props.disabled}
              hideLabel
            />
            <div className='absolute top-0 -right-2 transform translate-x-full'>
              <BrushSizePicker stageman={stageman} active={!!state.brushMode} />
            </div>
          </div>
        </div>
      </div>
      <div>
        <button
          type='button'
          className='btn text-white w-12.5 h-12.5 bg-transparent 
          lg:hover:bg-light-gray active:bg-light-gray 
          flex items-center justify-center rounded-lg'
          onClick={() => stageman.undo()}
          disabled={state.historyLength === 0 || props.disabled}
        >
          <UndoIcon2 className='w-8 h-8 fill-current' />
        </button>
      </div>
    </div>
  );
}

function WithStagemenSnapshot(props: {
  stageman: DrawingStageManager;
  children: (snap: DrawingStageManager['state']) => JSX.Element;
}) {
  const { stageman, children } = props;
  const state = useSnapshot(stageman.state);
  return children(state as ValtioSnapshottable<DrawingStageManager['state']>);
}

function StagemanSnapshotRequired(props: {
  stageman: DrawingStageManager | null;
  children: (snap: DrawingStageManager['state']) => JSX.Element;
}) {
  const { stageman } = props;

  if (!stageman) return null;
  return <WithStagemenSnapshot stageman={stageman} children={props.children} />;
}

function GradeStat(props: {
  threshold: number;
  actual: number;
  type: 'correct' | 'missed' | 'wrong';
}) {
  const { threshold, actual, type } = props;
  const [r, g, b, a] = DrawingStageManager.colors[type];
  return (
    <div className='flex flex-col gap-3 items-center justify-center'>
      <div className='flex items-center gap-1.5'>
        <div
          className='w-3.5 h-3.5 rounded-full'
          style={{
            backgroundColor: `rgba(${r}, ${g}, ${b}, ${a / 255})`,
          }}
        ></div>
        <div className='text-sms text-white capitalize'>{type}</div>
      </div>
      <div className='text-2xl text-white'>{Math.round(actual * 100)}%</div>
      <div className='text-sms text-icon-gray text-center'>
        {`Achieved Goal (${type === 'correct' ? '>' : '<'}${Math.round(
          threshold * 100
        )}%)`}
      </div>
    </div>
  );
}

export function DrawToWinBlockPlayground(props: {
  block: DrawToWinBlock;
  ctrl: DrawToWinBlockControlAPI;
}) {
  const { block, ctrl } = props;
  const backgroundMedia = block.fields.media?.media;
  const ref = useRef<HTMLDivElement>(null);
  const anchor = useRef<HTMLDivElement>(null);
  const { status, visibility, result, cta, grade } = useSnapshot(ctrl.state);
  const [stageman, setStageman] = useState<DrawingStageManager | null>(null);
  const [showGestureHint, setShowGestureHint] = useState(false);

  useEffectOnce(() => {
    ctrl.present();
  });

  useEffect(() => {
    if (!ref.current || !anchor.current) return;
    const container = ref.current;
    const aborter = new AbortController();
    const stageman = new DrawingStageManager(
      container,
      anchor.current,
      backgroundMedia,
      'game',
      aborter.signal
    );
    stageman.toggleBrushMode('brush');
    if (block.fields.matchTool) {
      stageman.setTool({
        name: block.fields.matchTool.name,
        icon: block.fields.matchTool.icon?.media?.url,
      });
    }
    setStageman(stageman);
    return () => {
      aborter.abort();
      stageman.destroy();
    };
  }, [backgroundMedia, block.fields.matchTool]);

  useEffect(() => {
    if (!stageman) return;
    if (ctrl.deps.storage.get('training-gameplay')?.guestureHintAcked) {
      return;
    }
    setShowGestureHint(true);
    const off = stageman.on('zoom', () => {
      off();
      const val = ctrl.deps.storage.get('training-gameplay');
      ctrl.deps.storage.set('training-gameplay', {
        ...val,
        guestureHintAcked: true,
      });
      setShowGestureHint(false);
    });
    return () => off();
  }, [ctrl.deps.storage, stageman]);

  const {
    call: handleSubmit,
    state: { state: submitState },
  } = useLiveAsyncCall(async () => {
    if (!stageman) return;
    stageman.toggleBrushMode(false);
    await ctrl.grade(() => {
      if (block.fields.matchTool?.drawing) {
        stageman.import(block.fields.matchTool?.drawing, 'base');
      }
      return stageman.grade();
    });
  });

  return (
    <>
      <BlurBackground backgroundMedia={backgroundMedia} />
      <div className='relative w-full h-full min-h-0 flex flex-col'>
        <main
          className={`
          w-full flex-1 min-h-0 px-1 pt-10 flex flex-col justify-center gap-10 lg:gap-5
          transition-opacity duration-500 ${
            visibility === 'visible' ? 'opacity-100' : 'opacity-0'
          }
        `}
        >
          {!result ? (
            <div className='w-full flex flex-col gap-5'>
              <div className='text-white text-center break-words text-base sm:text-xl lg:text-2xl'>
                {block.fields.question}
              </div>
              <div className='text-white font-bold italic text-center text-base sm:text-xl lg:text-2xl'>
                <p className='block lg:hidden'>Tap and drag to draw</p>
                <p className='hidden lg:block'>Click and drag to draw</p>
              </div>
            </div>
          ) : (
            <div className='flex items-center justify-center gap-5 lg:gap-10'>
              <GradeStat
                threshold={block.fields.matchTool?.correctThreshold ?? 0}
                actual={grade?.correct ?? 0}
                type='correct'
              />
              <GradeStat
                threshold={block.fields.matchTool?.missedThreshold ?? 0}
                actual={grade?.missed ?? 0}
                type='missed'
              />
              <GradeStat
                threshold={block.fields.matchTool?.wrongThreshold ?? 0}
                actual={grade?.wrong ?? 0}
                type='wrong'
              />
            </div>
          )}
          <div className='w-full flex flex-col gap-2 flex-1'>
            {stageman && (
              <Toolbar stageman={stageman} disabled={status !== 'play'} />
            )}
            <div className='w-full relative flex-1 bg-secondary bg-opacity-40 rounded-xl'>
              <div
                ref={anchor}
                className='w-full h-full absolute inset-0 pointer-events-none'
              />
              <div
                ref={ref}
                className='rounded-lg w-full flex-1 outline-none overflow-hidden absolute'
                tabIndex={0}
              />
              <Transition
                show={showGestureHint}
                enter='transition-opacity duration-1000'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='transition-opacity duration-150'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div
                  className='absolute transform left-1/2 top-1/2 -translate-x-1/2 
                -translate-y-1/2 bg-black bg-opacity-80 rounded-2.5xl text-white px-8 py-4
                text-xl font-bold whitespace-nowrap'
                >
                  <div className='items-center justify-center gap-2 flex lg:hidden'>
                    <img
                      src={getStaticAssetPath('images/pinch-to-zoom.png')}
                      className='w-7.5'
                      alt='pinch-to-zoom'
                    />
                    <p>Pinch to Zoom</p>
                  </div>
                  <div className='items-center justify-center gap-2 hidden lg:flex'>
                    <img
                      src={getStaticAssetPath('images/scroll-to-zoom.png')}
                      className='w-7.5'
                      alt='scroll-to-zoom'
                    />
                    <p>Scroll to Zoom</p>
                  </div>
                </div>
              </Transition>
            </div>
          </div>
        </main>
        <footer
          className={`
          w-full px-3 pt-3 pb-5 flex flex-col items-center relative
          transition-opacity duration-500 ${
            visibility === 'visible' ? 'opacity-100' : 'opacity-0'
          }
        `}
        >
          <div className='w-full h-5 flex items-center justify-center text-xs text-black text-opacity-80 text-center'>
            {result === 'correct' ? (
              <span className='text-green-001'>Nice job!</span>
            ) : result === 'incorrect' ? (
              <span className='text-red-006'>
                Room for improvement! See above
              </span>
            ) : null}
          </div>
          {cta === 'submit' ? (
            <StagemanSnapshotRequired stageman={stageman}>
              {(snap) => (
                <CommonButton
                  variant='brand'
                  onClick={handleSubmit}
                  disabled={
                    status !== 'play' ||
                    snap.historyLength === 0 ||
                    submitState.isRunning
                  }
                >
                  <div className='w-full flex items-center justify-center gap-2'>
                    {submitState.isRunning ? (
                      <Loading text='Grading...' />
                    ) : (
                      'Finish Job'
                    )}
                  </div>
                </CommonButton>
              )}
            </StagemanSnapshotRequired>
          ) : (
            <CommonButton
              variant={result ?? 'incorrect'}
              onClick={() => props.ctrl.end()}
              disabled={status !== 'complete'}
            >
              Continue
            </CommonButton>
          )}
          {result === 'correct' && (
            <CorrectAnimationWithLayout onEnd={() => void 0} />
          )}
        </footer>
      </div>
    </>
  );
}

function getCompletionPrompt(ctx: {
  question: string;
  correct: { target: string; user: string };
  missed: { target: string; user: string };
  wrong: { target: string; user: string };
}) {
  return `
\`\`\`openai { "model": "gpt-4o-mini", "temperature": 1, "maxTokens": 1280 }
You are a scriptwriter for an online platform called Luna Park. A user is playing a drawing game used to simulate a specific part of their job. This game gives the user a prompt that indicates there are hidden areas on the screen, and the user is going to match the hidden areas by drawing on the screen. Your job is to write a script that informs the user about their performance in this simulation.

Once the user is done, we will match the hidden areas and the user drawings on the pixel level, and calculate the performance on three dimensions, each dimension has a target threshold in form of a percentage.

* Correct: User drew where they were supposed to draw. User's performance should be higher than the target threshold.
* Missed: User missed parts of where they were supposed to draw. User's performance should be lower than the target threshold.
* Wrong: User drew outside of where they were supposed to draw. User's performance should be lower than the target threshold.

I will provide you the target threshold and user's performance, the values normally between 0-1, but it could be bigger if user drew too much outside. I will also provide you with the original prompt for the user, so you can have context into what the user was doing. If no original prompt is provided, you can provide a generic response based on the scores. 

Your script should be short and creative and incorporate words that acknowledge what the challenge was based on the prompt provided. It should be positive and encouraging, but not overly so. Assume the user has been playing games before this one, and that they will play more in the future, so the script should not be so final.

Note: The 'Evaluation' is your thought process on how to evaluate the user's performance based on the three dimensions. The 'Output' is the script you should write based on the evaluation.

<example>
Correct: target - 0.8, user - 0.9
Missed: target - 0.15, user - 0.1
Wrong: target - 0.05, user - 0.04
Original Prompt: Use the brush to sweep the outside of this house.
Evaluation: all three dimensions are within the target threshold.
Output: Your sweeping technique is spot on! The house's exterior is looking pristine. 
</example>

<example>
Correct: target - 0.8, user - 0.82
Missed: target - 0.15, user - 0.18
Wrong: target - 0.05, user - 0.08
Original Prompt: Use the scalpel to make the incision in the arm.
Evaluation: Your incision precision is good, but watch those margins! A steady hand will get you there.
</example>

<example>
Correct: target - 0.8, user - 0.52
Missed: target - 0.15, user - 0.48
Wrong: target - 0.05, user - 0.08
Original Prompt: Treat the front yard with fertilizer. Only put the fertilizer where it belongs!  
Evaluation: User didn't get enough correct parts, and missed some parts and drew some wrong parts.
Output: Some spots could use more nutrients while others got a bit too much fertilizer. Keep working on that coverage!
</example>

<example>
Correct: target - 0.8, user - 0.1
Missed: target - 0.15, user - 0.9
Wrong: target - 0.05, user - 0.15
Original Prompt: Identify the cavities on this patients teeth. 
Evaluation: User got very few correct parts, missed a lot of parts and drew a lot of wrong parts.
Output: Your cavity detection needs some work - quite a few problem areas were missed. Keep practicing
</example>


Output only the script, with nothing before or after.

Correct: target - ${ctx.correct.target}, user - ${ctx.correct.user}
Missed: target - ${ctx.missed.target}, user - ${ctx.missed.user}
Wrong: target - ${ctx.wrong.target}, user - ${ctx.wrong.user}
Original Prompt: ${ctx.question}
\`\`\`
`.trim();
}
