import { type QuestionBlock } from '@lp-lib/game';
import type { BlockOutputsDesc } from '@lp-lib/game/src/block-outputs';

const outputSchema: BlockOutputsDesc = {
  submission: {
    name: 'submission',
    description: 'Set when the user inputs a response to the block.',
    schema: {
      type: 'string',
    },
  },
  grade: {
    name: 'grade',
    description: 'Set when the block evaluates the user selection.',
    schema: {
      type: 'enum',
      values: ['correct', 'incorrect'],
    },
  },
};

export function getOutputSchema(_block: QuestionBlock) {
  return outputSchema;
}
