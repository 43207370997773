import { useEffect, useMemo, useRef } from 'react';
import { useEffectOnce, usePrevious } from 'react-use';
import { proxy } from 'valtio';

import {
  EnumsDialogueGenerationType,
  EnumsDialogueOfflineRenderStatus,
  EnumsSlideBackgroundOption,
  EnumsSlideLayout,
  EnumsTTSCacheControl,
  EnumsTTSRenderPolicy,
  type ModelsDialogue,
  type ModelsDialogueEntry,
} from '@lp-lib/api-service-client/public';
import type { SlideBlock, SlideBlockAnimationKey } from '@lp-lib/game';
import { type Logger } from '@lp-lib/logger-base';

import { fromMediaDTO } from '../../../utils/api-dto';
import { assertExhaustive, sleep, xDomainifyUrl } from '../../../utils/common';
import { ImagePickPriorityHighToLow, MediaUtils } from '../../../utils/media';
import {
  UnplayableAudioImpl,
  UnplayableImageImpl,
} from '../../../utils/unplayable';
import { markSnapshottable, useSnapshot } from '../../../utils/valtio';
import { RefreshIcon } from '../../icons/RefreshIcon';
import { useOrgBrandColor } from '../../VenueOrgLogoAverageColor/useOrgBrandColor';
import {
  lvoLocalCacheWarm,
  LVOLocalPlayer,
} from '../../VoiceOver/LocalLocalizedVoiceOvers';
import { usePersonality } from '../../VoiceOver/usePersonalities';
import { type VariableRegistry } from '../../VoiceOver/VariableRegistry';
import {
  BlockAnimation,
  BlockAnimator,
  BlockAnimatorProvider,
  useBlockAnimator,
} from '../apis/BlockAnimationControl';
import { CommonButton } from '../design/Button';
import { Captions } from '../design/Captions';
import { EditableTextDisplay } from '../design/EditableDisplay';
import { ScrollableContent } from '../design/ScrollableContent';
import { TrainingLogo } from '../design/TrainingLogo';
import {
  type BlockDependencies,
  type IBlockCtrl,
  type PlaygroundPlaybackProtocol,
} from '../types';

const Animate = BlockAnimation<SlideBlockAnimationKey>;

function newAvatarVideoElement(entry: ResolvedDialogueEntry) {
  const avatar = entry.offlineRender?.avatar;
  const avatarVideoUrl = MediaUtils.PickMediaUrl(fromMediaDTO(avatar?.media));
  if (!avatarVideoUrl) return null;

  const avatarPosterUrl = MediaUtils.PickMediaUrl(fromMediaDTO(avatar?.media), {
    videoThumbnail: 'first',
  });

  const video = document.createElement('video');
  video.preload = 'auto';
  video.muted = true;
  video.playsInline = true;
  video.className = 'w-full h-full object-cover';
  video.src = avatarVideoUrl;
  if (avatarPosterUrl) {
    video.poster = avatarPosterUrl;
  }
  return video;
}

export class SlideBlockControlAPI implements IBlockCtrl {
  private _state = markSnapshottable(
    proxy<{
      status: 'init' | 'present' | 'presented';
      playingDialogueEntryIndex: number;
      playingDialogueEntry: Nullable<ResolvedDialogueEntry>;
    }>({
      status: 'init',
      playingDialogueEntryIndex: -1,
      playingDialogueEntry: null,
    })
  );
  private delegate: Nullable<PlaygroundPlaybackProtocol>;
  private logger: Logger;
  blockAnimator: BlockAnimator<SlideBlockAnimationKey>;
  public videoCache: Map<string, HTMLVideoElement>;
  // note: this is bounded by the size of the audio pool.
  private preloadCount = 3;
  private dialoguePlayer: DialoguePlayer;
  public firstAvatarFallbackUrl: Nullable<string>;

  constructor(private block: SlideBlock, private deps: BlockDependencies) {
    this.logger = deps.getLogger('slide-block');
    this.blockAnimator = new BlockAnimator(block.fields.animations ?? {});
    this.videoCache = new Map();
    this.dialoguePlayer = new DialoguePlayer(
      block.fields.dialogue,
      deps.commonVariableRegistry,
      this.videoCache,
      this.logger,
      this.preloadCount
    );
  }

  get state() {
    return this._state;
  }

  async preload() {
    const entries = this.block.fields.dialogue?.entries ?? [];
    if (entries.length === 0) return;

    // preload the dialogue
    const preloads: Promise<void>[] = [];
    preloads.push(this.dialoguePlayer.preload());

    // preload the first avatar fallback image, if there is one.
    this.firstAvatarFallbackUrl =
      entries.at(0)?.offlineRender?.avatar?.media?.firstThumbnailUrl;
    if (this.firstAvatarFallbackUrl) {
      const img = new UnplayableImageImpl(
        xDomainifyUrl(this.firstAvatarFallbackUrl)
      );
      preloads.push(img.intoPlayable().then(() => void 0));
    }

    await Promise.all(preloads);
  }

  async initialize(preloaded: Promise<void>) {
    await preloaded;
    this.blockAnimator.on();
  }

  setDelegate(delegate: PlaygroundPlaybackProtocol) {
    this.delegate = delegate;
  }

  async present() {
    this._state.status = 'present';
    try {
      const info = this.dialoguePlayer.play((entry, index) => {
        this._state.playingDialogueEntry = entry;
        this._state.playingDialogueEntryIndex = index;
      });
      await info.started;
      this.blockAnimator.triggerAnimations('start');
      await info.ended;
      this.blockAnimator.triggerAnimations('end');
    } catch (e) {
      await sleep(3000);
    }
    this._state.status = 'presented';
    if (this.block.fields.hideButton) {
      this.end();
    }
  }

  async replay() {
    this.blockAnimator.reset(this.block.fields.animations ?? {});
    this.present();
  }

  async end() {
    this.videoCache.clear();
    this.blockAnimator.destroy();
    this.deps.sfxControl.play('instructionHoverReadyButton');
    await this.delegate?.blockDidEnd();
  }
}

function Logo() {
  const animator = useBlockAnimator<SlideBlockAnimationKey>();
  return <TrainingLogo ref={animator.ref('logo')} />;
}

function BigTitle(props: { block: SlideBlock }) {
  return (
    <div className='w-full flex flex-col gap-5 p-5'>
      <Logo />
      <Animate
        name='heading'
        className='text-3.5xl sm:text-4.25xl lg:text-5xl font-bold leading-normal'
      >
        <EditableTextDisplay value={props.block.fields.heading ?? ''} />
      </Animate>
      <Animate name='subtitle' className='text-base sm:text-xl lg:text-xl'>
        <EditableTextDisplay value={props.block.fields.subtitle ?? ''} />
      </Animate>
    </div>
  );
}

function Classic(props: { block: SlideBlock }) {
  return (
    <div className='w-full flex flex-col sm:flex-row gap-5 p-5'>
      <div className='w-full sm:w-1/2 flex flex-col gap-5'>
        <Logo />
        <Animate
          name='heading'
          className='text-xl sm:text-2xl lg:text-3.5xl font-bold leading-normal'
        >
          <EditableTextDisplay value={props.block.fields.heading ?? ''} />
        </Animate>
        <Animate name='subtitle' className='text-base sm:text-xl lg:text-2xl'>
          <EditableTextDisplay value={props.block.fields.subtitle ?? ''} />
        </Animate>
      </div>
      <Animate name='media' className='w-full sm:w-1/2'>
        <img
          src={props.block.fields.media?.media?.url}
          alt={''}
          className='w-full h-full object-contain rounded-xl'
        />
      </Animate>
    </div>
  );
}

function BulletPoints(props: { block: SlideBlock }) {
  return (
    <div className='w-full flex flex-col gap-5 p-5'>
      <Logo />

      <Animate
        name='heading'
        className='text-xl sm:text-2xl lg:text-3.5xl font-bold leading-normal'
      >
        <EditableTextDisplay value={props.block.fields.heading ?? ''} />
      </Animate>
      <ol
        className={`
          list-decimal break-words
          text-base sm:text-xl lg:text-2xl
          space-y-2 sm:space-y-4 pl-5 sm:pl-8 lg:pl-10
        `}
      >
        {props.block.fields.bulletPoints.map((point, index) => (
          <BlockAnimation<SlideBlockAnimationKey, 'li'>
            key={index}
            name={`bullet-${index + 1}`}
            as='li'
          >
            {point}
          </BlockAnimation>
        ))}
      </ol>
    </div>
  );
}

function TitleAndText(props: { block: SlideBlock }) {
  return (
    <div className='w-full flex flex-col gap-5 p-5'>
      <Logo />
      <Animate
        name='heading'
        className='text-xl sm:text-2xl lg:text-3.5xl font-bold leading-normal'
      >
        <EditableTextDisplay value={props.block.fields.heading ?? ''} />
      </Animate>
      <Animate name='subtitle' className='text-base sm:text-xl lg:text-xl'>
        <EditableTextDisplay value={props.block.fields.subtitle ?? ''} />
      </Animate>
    </div>
  );
}
function Quote(props: { block: SlideBlock }) {
  return (
    <div className='w-full h-full flex flex-col lg:justify-center p-5'>
      <div className='flex flex-col gap-5 md:max-w-2xl mt-18 lg:mt-0 lg:justify-center'>
        <Logo />
        <Animate
          name='heading'
          className='text-2.5xl sm:text-3.5xl md:text-4.5xl font-bold leading-normal justify-start'
        >
          <EditableTextDisplay
            value={`“${props.block.fields.heading}”` ?? ''}
          />
        </Animate>
        <Animate name='subtitle' className='text-base sm:text-xl lg:text-xl'>
          <EditableTextDisplay value={props.block.fields.subtitle ?? ''} />
        </Animate>
      </div>
    </div>
  );
}

function QuoteMedia(props: { block: SlideBlock }) {
  return (
    <div className='w-full h-full flex flex-col lg:justify-center p-5'>
      <div className='flex flex-col sm:flex-row sm:items-center sm:justify-between md:gap-8 mt-18 lg:mt-0'>
        <div className='flex flex-col gap-5 md:w-1/2'>
          <Logo />
          <Animate
            name='heading'
            className='text-xl sm:text-2xl md:text-3.5xl font-bold leading-normal justify-start'
          >
            <EditableTextDisplay
              value={`“${props.block.fields.heading}”` ?? ''}
            />
          </Animate>
          <Animate name='subtitle' className='text-base sm:text-xl lg:text-xl'>
            <EditableTextDisplay value={props.block.fields.subtitle ?? ''} />
          </Animate>
        </div>
        <Animate name='media' className='sm:w-1/2 mt-4 sm:mt-0 sm:pl-2'>
          <img
            src={props.block.fields.media?.media?.url}
            alt=''
            className='w-full h-full object-contain rounded-xl'
          />
        </Animate>
      </div>
    </div>
  );
}

const BigTitleMedia = (props: { block: SlideBlock }) => {
  return (
    <div className='w-full h-full flex flex-col sm:flex-row sm:items-center sm:justify-between md:gap-8 p-5'>
      <div className='flex flex-col gap-4 md:w-1/2'>
        <Logo />
        <Animate
          name='heading'
          className='text-3.5xl sm:text-4.25xl lg:text-5xl font-bold leading-normal'
        >
          <EditableTextDisplay value={props.block.fields.heading ?? ''} />
        </Animate>
        <Animate name='subtitle' className='text-base sm:text-xl lg:text-xl'>
          <EditableTextDisplay value={props.block.fields.subtitle ?? ''} />
        </Animate>
      </div>
      <Animate name='media' className='sm:w-1/2 mt-4 sm:mt-0 sm:pl-2'>
        <div className='flex items-center justify-center'>
          <img
            src={props.block.fields.media?.media?.url}
            alt=''
            className='w-full h-full object-contain rounded-xl'
          />
        </div>
      </Animate>
    </div>
  );
};

function BulletPointsMedia(props: { block: SlideBlock }) {
  return (
    <div className='w-full h-full flex flex-col md:justify-center p-5'>
      <div className='flex flex-col sm:flex-row sm:items-start sm:justify-between md:gap-8'>
        <div className='flex flex-col gap-5 md:w-1/2'>
          <Logo />
          <Animate
            name='heading'
            className='text-xl sm:text-2xl lg:text-3.5xl font-bold leading-normal'
          >
            <EditableTextDisplay value={props.block.fields.heading ?? ''} />
          </Animate>
          <ol
            className={`
                min-h-0 flex-1
                list-decimal break-words
                text-base sm:text-xl lg:text-2xl
                space-y-2 sm:space-y-4 pl-5 sm:pl-8 lg:pl-10
            `}
          >
            {props.block.fields.bulletPoints.map((point, index) => (
              <BlockAnimation<SlideBlockAnimationKey, 'li'>
                key={index}
                name={`bullet-${index + 1}`}
                as='li'
              >
                {point}
              </BlockAnimation>
            ))}
          </ol>
        </div>
        <Animate name='media' className='sm:w-1/2 mt-4 sm:mt-0 sm:pl-2'>
          <img
            className='w-full h-full object-contain rounded-xl'
            src={props.block.fields.media?.media?.url}
            alt=''
          />
        </Animate>
      </div>
    </div>
  );
}

function BigMedia(props: { block: SlideBlock }) {
  return (
    <Animate
      name='media'
      className='w-full h-full flex justify-center items-center p-5'
    >
      <img
        src={props.block.fields.media?.media?.url}
        alt=''
        className='w-full object-contain rounded-xl'
      />
    </Animate>
  );
}

function Content(props: { block: SlideBlock }) {
  switch (props.block.fields.layout) {
    case EnumsSlideLayout.SlideLayoutBigTitle:
      return <BigTitle block={props.block} />;
    case EnumsSlideLayout.SlideLayoutClassic:
      return <Classic block={props.block} />;
    case EnumsSlideLayout.SlideLayoutBulletPoints:
      return <BulletPoints block={props.block} />;
    case EnumsSlideLayout.SlideLayoutTitleAndText:
      return <TitleAndText block={props.block} />;
    case EnumsSlideLayout.SlideLayoutFullHost:
      return null;
    case EnumsSlideLayout.SlideLayoutQuote:
      return <Quote block={props.block} />;
    case EnumsSlideLayout.SlideLayoutQuoteMedia:
      return <QuoteMedia block={props.block} />;
    case EnumsSlideLayout.SlideLayoutBigTitleMedia:
      return <BigTitleMedia block={props.block} />;
    case EnumsSlideLayout.SlideLayoutBulletPointsMedia:
      return <BulletPointsMedia block={props.block} />;
    case EnumsSlideLayout.SlideLayoutBigMedia:
      return <BigMedia block={props.block} />;
    default:
      return null;
  }
}

function AvatarPlaceholder(props: {
  personalityId: string;
  className?: string;
  showPlaceholder?: boolean;
}) {
  // TODO(guoqiang): preload avatar when playground init.
  const personality = usePersonality(props.personalityId);
  const mediaUrl = MediaUtils.PickMediaUrl(
    fromMediaDTO(personality?.profileImage?.media),
    {
      priority: ImagePickPriorityHighToLow,
    }
  );

  if (!mediaUrl) return null;
  return (
    <div className={`relative ${props.className}`}>
      <img src={mediaUrl} alt='' className='w-full h-full object-cover' />
      {props.showPlaceholder && (
        <div className='absolute inset-0 rounded-full flex justify-center items-center'>
          <p className={`text-icon-gray font-bold text-5xl`}>Placeholder</p>
        </div>
      )}
    </div>
  );
}

function FullHostAvatar(props: {
  ctrl: SlideBlockControlAPI;
  className?: string;
}) {
  const status = useSnapshot(props.ctrl.state).status;
  const prevStatus = usePrevious(status);
  const playingEntry = useSnapshot(props.ctrl.state)
    .playingDialogueEntry as Nullable<ResolvedDialogueEntry>;
  const playingEntryIndex = useSnapshot(
    props.ctrl.state
  ).playingDialogueEntryIndex;
  const ref = useRef<HTMLDivElement>(null);

  const body = useMemo(() => {
    if (!playingEntry) {
      // no playing entry...but maybe we're preparing and we can show the firstFrame
      if (
        props.ctrl.firstAvatarFallbackUrl &&
        (status === 'init' || playingEntryIndex <= 0)
      ) {
        return (
          <img
            src={props.ctrl.firstAvatarFallbackUrl}
            alt=''
            className='w-full h-full object-cover'
          />
        );
      }
      return null;
    }

    const avatar = playingEntry?.offlineRender?.avatar;
    if (!avatar) {
      return (
        <AvatarPlaceholder
          personalityId={playingEntry.personalityId}
          className={props.className}
          showPlaceholder={
            playingEntry.offlineRender?.status ===
            EnumsDialogueOfflineRenderStatus.DialogueOfflineRenderStatusProcessing
          }
        />
      );
    }
  }, [
    playingEntry,
    playingEntryIndex,
    props.className,
    props.ctrl.firstAvatarFallbackUrl,
    status,
  ]);

  const reply = prevStatus === 'presented' && status === 'present';
  useEffect(() => {
    if (!playingEntry?.id) return;

    const video = props.ctrl.videoCache.get(playingEntry.id);
    if (video) {
      video.currentTime = 0;
      video.autoplay = true;
      if (ref.current?.firstChild) {
        ref.current?.replaceChild(video, ref.current.firstChild);
      } else {
        ref.current?.appendChild(video);
      }
      video.play();
    }
  }, [playingEntry?.id, reply, props.ctrl.videoCache]);

  return (
    <div className={props.className} ref={ref}>
      {body}
    </div>
  );
}

function Background(props: { block: SlideBlock; ctrl: SlideBlockControlAPI }) {
  const { color } = useOrgBrandColor();

  if (props.block.fields.layout === EnumsSlideLayout.SlideLayoutFullHost)
    return (
      <div className='fixed inset-0'>
        <FullHostAvatar {...props} className='w-full h-full object-cover' />
      </div>
    );
  if (
    props.block.fields.backgroundOption ===
    EnumsSlideBackgroundOption.SlideBackgroundOptionHost
  ) {
    return (
      <div className='fixed inset-0'>
        <FullHostAvatar {...props} className='w-full h-full object-cover' />
        <Animate
          name='overlay'
          className={`absolute inset-0 bg-opacity-60 bg-black`}
        />
      </div>
    );
  }
  if (
    props.block.fields.backgroundOption ===
    EnumsSlideBackgroundOption.SlideBackgroundOptionBrand
  ) {
    return (
      <div
        className='fixed inset-0 opacity-20'
        style={{ backgroundColor: color }}
      />
    );
  }
  return null;
}

export function SlideBlockPlaygroundInternal(props: {
  block: SlideBlock;
  ctrl: SlideBlockControlAPI;
  subtitlesArea: JSX.Element;
}) {
  const status = useSnapshot(props.ctrl.state).status;

  useEffectOnce(() => {
    props.ctrl.present();
  });

  const isFullHostLayout =
    props.block.fields.layout === EnumsSlideLayout.SlideLayoutFullHost;
  const showContent = !isFullHostLayout;

  return (
    <>
      <Background block={props.block} ctrl={props.ctrl} />

      <div className={'relative w-full h-full flex flex-col text-white'}>
        {isFullHostLayout && <Captions>{props.subtitlesArea}</Captions>}

        {showContent && (
          <div className='flex-1 min-h-0 p-5 sm:p-8 lg:p-10'>
            <ScrollableContent>
              <Content block={props.block} />
            </ScrollableContent>
          </div>
        )}

        {!props.block.fields.hideButton && (
          <footer
            className={`${
              isFullHostLayout ? 'mt-auto' : ''
            } w-full flex items-center justify-center gap-2 p-3 pb-5`}
          >
            <CommonButton
              variant='gray'
              onClick={() => {
                props.ctrl.replay();
              }}
              disabled={status !== 'presented'}
              styles={{ size: 'h-full' }}
              style={{
                aspectRatio: '1/1',
              }}
            >
              <RefreshIcon className='w-4 h-4 fill-current' />
            </CommonButton>
            <CommonButton
              variant='brand'
              onClick={() => {
                props.ctrl.end();
              }}
              disabled={status !== 'presented'}
              className='flex-none'
            >
              <EditableTextDisplay
                value={props.block.fields.buttonText ?? ''}
              />
            </CommonButton>
          </footer>
        )}
      </div>
    </>
  );
}

export function SlideBlockPlayground(props: {
  block: SlideBlock;
  ctrl: SlideBlockControlAPI;
  subtitlesArea: JSX.Element;
}) {
  return (
    <BlockAnimatorProvider value={props.ctrl.blockAnimator}>
      <SlideBlockPlaygroundInternal {...props} />
    </BlockAnimatorProvider>
  );
}

// TODO(falcon): consider sharing this.

type ResolvedDialogueEntry = ModelsDialogueEntry & {
  resolvedScript: string;
};

class DialoguePlayer {
  private entries: ModelsDialogueEntry[];
  private resolvedEntries: Map<
    ModelsDialogueEntry['id'],
    ResolvedDialogueEntry
  >;
  private preloaded: Set<ModelsDialogueEntry['id']>;

  constructor(
    dialogue: Nullable<ModelsDialogue>,
    private vr: VariableRegistry,
    private videoCache: Map<string, HTMLVideoElement>,
    private logger: Logger,
    private preloadCount = 3
  ) {
    this.entries = dialogue?.entries ?? [];
    this.resolvedEntries = new Map();
    this.preloaded = new Set();
  }

  /**
   * Attempts to preload the first `preloadCount` entries. Note that some entries may not be preloadable in case they
   * depend on variables that are not yet resolvable. These entries are skipped, and at most`preloadCount` entries are
   * preloaded.
   */
  async preload(): Promise<void> {
    await this.resolve();
    if (this.resolvedEntries.size === 0) return;

    const entries = Array.from(
      this.getPreloadableEntries({ limit: this.preloadCount })
    );
    const preloads = entries.map(async (entry) => {
      await this.preloadResolvedDialogueEntry(entry);
      this.preloaded.add(entry.id);
    });
    await Promise.all(preloads);
  }

  play(
    onEntryPlaying?: (entry: ResolvedDialogueEntry, index: number) => void
  ): { started: Promise<void>; ended: Promise<void> } {
    const started = Promise.withResolvers<void>();
    const ended = Promise.withResolvers<void>();
    const result = {
      started: started.promise,
      ended: ended.promise,
    };

    (async () => {
      if (this.entries.length === 0) {
        started.resolve();
        ended.resolve();
        return;
      }

      // we're going to play, so we must have a resolved script for every entry. keep in mind that not all entries are
      // guaranteed to have been preloaded at this point.
      await this.resolve(true);

      let hasResolvedStarted = false;
      for (let i = 0; i < this.entries.length; i++) {
        const entry = this.entries[i];
        const resolvedEntry = this.resolvedEntries.get(entry.id) ?? {
          ...entry,
          resolvedScript: entry.script.trim(),
        };

        // skip anything with no script.
        if (resolvedEntry.resolvedScript.length === 0) continue;

        // we're going to play this entry, let's preload the next one.
        this.preloadNextEntry(i);

        try {
          const player = new LVOLocalPlayer({
            script: resolvedEntry.resolvedScript,
            personalityId: resolvedEntry.personalityId,
            cacheControl: EnumsTTSCacheControl.TTSCacheControlShortLive,
            policy: EnumsTTSRenderPolicy.TTSRenderPolicyReadThrough,
            renderedMedia: fromMediaDTO(
              resolvedEntry.offlineRender?.voiceOver?.media
            ),
          });
          const info = await player.playFromPool();
          await info?.trackStarted;
          if (onEntryPlaying) {
            onEntryPlaying(resolvedEntry, i);
          }
          if (!hasResolvedStarted) {
            // it's possible that the first entry failed to play for some reason...
            started.resolve();
            hasResolvedStarted = true;
          }
          await info?.trackEnded;
        } catch (e) {
          this.logger.error('failed to play dialogue entry', { e });
        }
        // little bit of jitter to make it feel more natural
        await sleep(Math.random() * (125 - 25) + 25);
      }
      if (!hasResolvedStarted) {
        // if we haven't resolved started, then we never started. mark it resolved so others continue.
        started.resolve();
      }
      ended.resolve();
    })();

    return result;
  }

  // resolve can be called multiple times. any unresolved entries will be resolved.
  private async resolve(must = false): Promise<void> {
    const resolutions = this.entries.map(async (entry) => {
      if (this.resolvedEntries.has(entry.id)) return;
      if (
        entry.generationType ===
        EnumsDialogueGenerationType.DialogueGenerationTypeClient
      ) {
        const result = await this.vr.render(entry.script);
        if (must || result.resolved) {
          this.resolvedEntries.set(entry.id, {
            ...entry,
            resolvedScript: result.script.trim(),
          });
        }
      } else {
        this.resolvedEntries.set(entry.id, {
          ...entry,
          resolvedScript: entry.script.trim(),
        });
      }
    });
    await Promise.all(resolutions);
  }

  private async preloadResolvedDialogueEntry(entry: ResolvedDialogueEntry) {
    if (entry.resolvedScript.length === 0 || this.preloaded.has(entry.id))
      return;
    switch (entry.generationType) {
      case EnumsDialogueGenerationType.DialogueGenerationTypeClient:
        await lvoLocalCacheWarm({
          script: entry.resolvedScript,
          personalityId: entry.personalityId,
          cacheControl: EnumsTTSCacheControl.TTSCacheControlShortLive,
          policy: EnumsTTSRenderPolicy.TTSRenderPolicyReadThrough,
        });
        return;
      case EnumsDialogueGenerationType.DialogueGenerationTypeOffline:
        const tasks: Promise<void>[] = [];
        const video = newAvatarVideoElement(entry);
        if (video) {
          this.videoCache.set(entry.id, video);
          tasks.push(
            new Promise<void>(async (resolve) => {
              try {
                await video.play();
                video.pause();
                video.currentTime = 0;
              } catch (e) {
                this.logger.error('Failed to preload avatar video', { e });
              } finally {
                resolve();
              }
            })
          );
        }

        const voiceOver = entry.offlineRender?.voiceOver;
        const voiceOverUrl = voiceOver?.media?.url;
        if (voiceOverUrl) {
          const audio = new UnplayableAudioImpl(xDomainifyUrl(voiceOverUrl));
          tasks.push(audio.intoPlayable().then(() => void 0));
        }

        await Promise.all(tasks);
        return;
      default:
        assertExhaustive(entry.generationType);
        return;
    }
  }

  private preloadNextEntry(after: number) {
    const nextEntryNeedingPreload = Array.from(
      this.getPreloadableEntries({ after, limit: 1 })
    ).at(0);
    if (nextEntryNeedingPreload) {
      this.preloadResolvedDialogueEntry(nextEntryNeedingPreload);
    }
  }

  private *getPreloadableEntries(opts: {
    after?: number;
    limit?: number;
  }): Generator<ResolvedDialogueEntry> {
    if (opts.limit && opts.limit < 1) return;

    let count = 0;
    const startIndex =
      opts.after !== undefined ? Math.max(opts.after, -1) + 1 : 0;

    for (let i = startIndex; i < this.entries.length; i++) {
      const entry = this.entries[i];
      if (this.preloaded.has(entry.id)) continue;

      const maybe = this.resolvedEntries.get(entry.id);
      if (maybe) {
        yield maybe;
        count++;
      }
      if (opts.limit && count >= opts.limit) break;
    }
  }
}
