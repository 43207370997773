import { ModelsLogicSettings } from '@lp-lib/api-service-client/public';
import { z } from 'zod';
import { Block } from './block';

const LogicArgSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal('output'),
    blockId: z.string(),
    outputName: z.string(),
  }),
  z.object({
    type: z.literal('constant'),
    value: z.union([z.string(), z.number()]),
  }),
]);
export type LogicArg = z.infer<typeof LogicArgSchema>;

const StringComparisonSchema = z.enum([
  'equal',
  'notEqual',
  'beginsWith',
  'endsWith',
  'contains',
  'notContains',
]);
export type StringComparison = z.infer<typeof StringComparisonSchema>;

const NumberComparisonSchema = z.enum(['eq', 'neq', 'lt', 'gt', 'lte', 'gte']);
export type NumberComparison = z.infer<typeof NumberComparisonSchema>;

const EnumComparisonSchema = z.enum(['is', 'isNot']);
export type EnumComparison = z.infer<typeof EnumComparisonSchema>;

const LogicConditionSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal('always'),
  }),
  z.object({
    type: z.literal('stringCmp'),
    op: StringComparisonSchema,
    args: z.array(LogicArgSchema),
  }),
  z.object({
    type: z.literal('numberCmp'),
    op: NumberComparisonSchema,
    args: z.array(LogicArgSchema),
  }),
  z.object({
    type: z.literal('enumCmp'),
    op: EnumComparisonSchema,
    args: z.array(LogicArgSchema),
  }),
]);
export type LogicCondition = z.infer<typeof LogicConditionSchema>;

const LogicActionSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal('jump'),
    props: z.object({
      toBlockId: z.string(),
    }),
  }),
]);
export type LogicAction = z.infer<typeof LogicActionSchema>;

const LogicTriggerSchema = z.object({
  type: z.enum(['onEnd']),
});
export type LogicTrigger = z.infer<typeof LogicTriggerSchema>;

const LogicRuleSchema = z.object({
  id: z.string(),
  trigger: LogicTriggerSchema,
  condition: LogicConditionSchema,
  actions: z.array(LogicActionSchema),
});
export type LogicRule = z.infer<typeof LogicRuleSchema>;

export const BlockLogicSchema = z.object({
  rules: z.array(LogicRuleSchema),
});
export type BlockLogic = z.infer<typeof BlockLogicSchema>;

export const LogicSettingsSchema = z.object({
  blockLogic: z.record(z.string(), BlockLogicSchema),
});
export type LogicSettings = z.infer<typeof LogicSettingsSchema>;

// utility functions for creating logic rules

export const Logic = {
  trigger: {
    onEnd: (): LogicTrigger => ({ type: 'onEnd' }),
  },
  args: {
    output: (blockId: Block['id'], outputName: string): LogicArg => ({
      type: 'output',
      blockId,
      outputName,
    }),
    constant: (value: string | number): LogicArg => ({
      type: 'constant',
      value,
    }),
  },
};

/**
 * Builds a map of block ids to the ids of rules that reference them.
 * @param settings
 */
export function buildBlockRefMap(
  settings: LogicSettings | null | undefined
): Map<Block['id'], LogicRule['id'][]> {
  const result = new Map<Block['id'], LogicRule['id'][]>();
  if (!settings) return result;

  for (const [blockId, blockLogic] of Object.entries(settings.blockLogic)) {
    const blocksRules = [];
    for (const rule of blockLogic.rules) {
      blocksRules.push(rule.id);
      for (const action of rule.actions) {
        // for now, only jump actions can reference other blocks
        if (action.type === 'jump') {
          const current = result.get(action.props.toBlockId) ?? [];
          current.push(rule.id);
          result.set(action.props.toBlockId, current);
        }
      }
    }
    const current = result.get(blockId) ?? [];
    current.push(...blocksRules);
    result.set(blockId, current);
  }

  return result;
}

/**
 * Removes the given logic rules from the settings. Returns a copy.
 * @param settings
 * @param ruleIds
 */
export function removeLogicRules(
  settings: LogicSettings | null | undefined,
  ruleIds: LogicRule['id'][]
): ModelsLogicSettings | null | undefined {
  if (!settings) return settings;

  const next = { ...settings.blockLogic };
  for (const [blockId, blockLogic] of Object.entries(next)) {
    const nextRules = [...blockLogic.rules].filter(
      (rule) => !ruleIds.includes(rule.id)
    );

    if (nextRules.length === 0) {
      delete next[blockId];
    } else {
      next[blockId] = { ...blockLogic, rules: nextRules };
    }
  }
  return { ...settings, blockLogic: next };
}
