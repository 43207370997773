import Konva from 'konva/lib/Core';
import { type Group as KonvaGroup } from 'konva/lib/Group';
import { type Layer as KonvaLayer } from 'konva/lib/Layer';
import { Node as KonvaNode } from 'konva/lib/Node';
import { type Shape as KonvaShape } from 'konva/lib/Shape';
import { Circle as KonvaCircle } from 'konva/lib/shapes/Circle';
import { Image as KonvaImage } from 'konva/lib/shapes/Image';
import { Line as KonvaLine } from 'konva/lib/shapes/Line';
import { Rect as KonvaRect } from 'konva/lib/shapes/Rect';
import { Transformer as KonvaTransformer } from 'konva/lib/shapes/Transformer';
import { proxy } from 'valtio';

import {
  type CommonMedia,
  type ModelsDrawToWinMatchTool,
} from '@lp-lib/api-service-client/public';
import { MediaFormatVersion } from '@lp-lib/media';

import { getLogger } from '../../../../logger/logger';
import { fromMediaDTO } from '../../../../utils/api-dto';
import { uuidv4 } from '../../../../utils/common';
import { Emitter, type EmitterListener } from '../../../../utils/emitter';
import { MediaUtils } from '../../../../utils/media';
import { markSnapshottable } from '../../../../utils/valtio';

type Point = { x: number; y: number };

function getDistance(p1: Point, p2: Point) {
  return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
}

function getCenter(p1: Point, p2: Point) {
  return {
    x: (p1.x + p2.x) / 2,
    y: (p1.y + p2.y) / 2,
  };
}

function getObjectFitSize(
  objectFit: 'contain' | 'cover',
  containerWidth: number,
  containerHeight: number,
  width: number,
  height: number
) {
  const doRatio = width / height;
  const cRatio = containerWidth / containerHeight;
  let targetWidth = 0;
  let targetHeight = 0;
  const test = objectFit === 'contain' ? doRatio > cRatio : doRatio < cRatio;

  if (test) {
    targetWidth = containerWidth;
    targetHeight = targetWidth / doRatio;
  } else {
    targetHeight = containerHeight;
    targetWidth = targetHeight * doRatio;
  }

  return {
    width: targetWidth,
    height: targetHeight,
    x: (containerWidth - targetWidth) / 2,
    y: (containerHeight - targetHeight) / 2,
  };
}

function updateData(
  data: Uint8ClampedArray,
  i: number,
  rgba: readonly number[]
) {
  data[i] = rgba[0];
  data[i + 1] = rgba[1];
  data[i + 2] = rgba[2];
  data[i + 3] = rgba[3];
}

export type Events = {
  zoom: () => void;
};

export class DrawingStageManager implements EmitterListener<Events> {
  static colors = {
    correct: [57, 217, 102, 255] as const,
    missed: [251, 183, 7, 255] as const,
    wrong: [255, 9, 53, 255] as const,
  };

  private emitter = new Emitter<Events>();
  on = this.emitter.on.bind(this.emitter);
  off = this.emitter.off.bind(this.emitter);

  private _state = markSnapshottable(
    proxy<{
      brushMode: 'brush' | 'eraser' | false;
      brushSize: number;
      brushColor: string;
      sceneWidth: number;
      sceneHeight: number;
      baseScale: number;
      userScale: number;
      historyLength: number;
      showGestureHint: boolean;
    }>({
      brushMode: false,
      brushSize: 4,
      brushColor: '#FBB707',
      sceneWidth: 0,
      sceneHeight: 0,
      baseScale: 1,
      userScale: 1,
      historyLength: 0,
      showGestureHint: false,
    })
  );
  private stage;
  private transformer;
  private layers: {
    // the background layer
    bg: KonvaLayer;
    // the drawing layer
    draw: KonvaLayer;
    // the base layer is used to restore the block’s "drawing". (game only)
    base: KonvaLayer;
    // the diff layer is used to display the difference between the base layer and the drawing layer. (game only)
    diff: KonvaLayer;
  };
  private currentLine: {
    buffer: KonvaLine;
    added: boolean;
  } | null = null;
  private dragStartPos: Point | null = null;
  private history: string[][] = [];
  private tool: {
    name: string;
    icon?: string;
  } | null = null;

  constructor(
    readonly container: HTMLDivElement,
    anchor: HTMLDivElement,
    backgroundMedia: CommonMedia | null | undefined,
    readonly mode: 'edit' | 'game',
    signal: AbortSignal,
    readonly logger = getLogger().scoped('stageman')
  ) {
    const format = MediaUtils.PickMediaFormat(fromMediaDTO(backgroundMedia), {
      priority: [MediaFormatVersion.Raw],
    });
    this._state.sceneWidth = format?.width ?? 0;
    this._state.sceneHeight = format?.height ?? 0;
    const rect = this.syncContainerRect(
      anchor,
      this._state.sceneWidth,
      this._state.sceneHeight
    );

    this.stage = new Konva.Stage({
      container,
      width: rect.width,
      height: rect.height,
    });

    this.logger.info('set stage initial size', {
      containerWidth: rect.width,
      containerHeight: rect.height,
    });
    this.transformer = new KonvaTransformer();
    const bgLayer = new Konva.Layer({ name: 'bgLayer' });
    this.stage.add(bgLayer);
    const drawLayer = new Konva.Layer({ name: 'drawLayer' });
    this.stage.add(drawLayer);
    const baseLayer = new Konva.Layer({ name: 'baseLayer', visible: false });
    const diffLayer = new Konva.Layer({ name: 'diffLayer', visible: false });

    if (mode === 'edit') {
      drawLayer.add(this.transformer);
    } else {
      this.stage.add(baseLayer);
      this.stage.add(diffLayer);
    }

    this.layers = {
      bg: bgLayer,
      draw: drawLayer,
      base: baseLayer,
      diff: diffLayer,
    };

    // load background image
    if (format?.url) {
      this.setBackgroundFromURL(format.url);
    }

    // init auto resize
    const fitStageIntoParentContainer = () => {
      const rect = this.syncContainerRect(
        anchor,
        this._state.sceneWidth,
        this._state.sceneHeight
      );

      // but we also make the full scene visible
      // so we need to scale all objects on canvas
      const newBaseScale = rect.width / this._state.sceneWidth;

      this.stage.width(this._state.sceneWidth * newBaseScale);
      this.stage.height(this._state.sceneHeight * newBaseScale);

      this.logger.debug('set stage size', { ...rect, newBaseScale });

      if (this._state.userScale === this._state.baseScale) {
        this.stage.scale({ x: newBaseScale, y: newBaseScale });
        this._state.baseScale = newBaseScale;
        this._state.userScale = newBaseScale;
      } else {
        // if user zoomed in/out, we need to adjust the stage based on user scale
        // and update the position accordingly. Actually this logic can cover the
        // above one, but we keep them separate to make it easier to understand.
        const newUserScale =
          (this._state.userScale / this._state.baseScale) * newBaseScale;
        const pos = this.stage.position();
        const newPos = {
          x: (pos.x / this._state.userScale) * newUserScale,
          y: (pos.y / this._state.userScale) * newUserScale,
        };
        this.stage.scale({ x: newUserScale, y: newUserScale });
        this.stage.position(newPos);
        this._state.baseScale = newBaseScale;
        this._state.userScale = newUserScale;
      }
    };

    fitStageIntoParentContainer();

    const resizeObserver = new ResizeObserver(fitStageIntoParentContainer);
    resizeObserver.observe(anchor);

    if (mode === 'edit') {
      this.initEditEvents(signal);
    } else {
      this.initGameEvents(signal);
    }
  }

  private syncContainerRect(
    anchor: HTMLDivElement,
    targetWidth: number,
    targetHeight: number
  ) {
    const rect = anchor.getBoundingClientRect();
    const fitRect = getObjectFitSize(
      'contain',
      rect.width,
      rect.height,
      targetWidth,
      targetHeight
    );
    this.container.style.width = `${fitRect.width}px`;
    this.container.style.height = `${fitRect.height}px`;
    this.container.style.left = `${fitRect.x}px`;
    this.container.style.top = `${fitRect.y}px`;
    return fitRect;
  }

  private initEditEvents(signal: AbortSignal) {
    const tr = this.transformer;

    let selecting = false;
    this.stage.on('mousedown', (e) => {
      if (e.evt.buttons !== 1) return;
      // selection
      if (e.target.hasName('drawing')) {
        e.evt.preventDefault();
        const pos = this.stage.getRelativePointerPosition();
        if (!pos) return;

        selecting = true;

        // do we pressed shift or ctrl?
        const metaPressed = e.evt.shiftKey || e.evt.ctrlKey || e.evt.metaKey;
        const isSelected = tr.nodes().indexOf(e.target) >= 0;

        if (!metaPressed && !isSelected) {
          // if no key pressed and the node is not selected
          // select just one
          tr.nodes([e.target]);
        } else if (metaPressed && isSelected) {
          // if we pressed keys and node was selected
          // we need to remove it from selection:
          const nodes = tr.nodes().slice(); // use slice to have new copy of array
          // remove node from array
          nodes.splice(nodes.indexOf(e.target), 1);
          tr.nodes(nodes);
        } else if (metaPressed && !isSelected) {
          // add the node into selection
          const nodes = tr.nodes().concat([e.target]);
          tr.nodes(nodes);
        }
        return;
      }

      // transformer grid is clicked
      if (e.target.parent === tr) return;

      if (this._state.brushMode) {
        e.evt.preventDefault();
        tr.nodes([]);
        selecting = false;
        this.startLine();
      }
    });

    this.stage.on('mousemove', (e) => {
      if (selecting) return;

      if (this.currentLine) {
        e.evt.preventDefault();
        this.moveLine();
      }
    });

    this.stage.on('mouseup', () => {
      // do nothing if we didn't start selection
      selecting = false;
      this.endLine();
    });

    this.stage.on('click tap', (e) => {
      this.container.focus();
      // if click on background - remove all selections
      if (e.target.getLayer() === this.layers.bg) {
        tr.nodes([]);
        return;
      }
    });

    this.container.addEventListener(
      'keydown',
      (e) => {
        if (e.key === 'Delete' || e.key === 'Backspace') {
          const nodes = tr.nodes();
          for (const node of nodes) {
            if (node.hasName('drawing')) {
              this.deleteDrawingShape(node as KonvaShape);
            }
          }
          e.preventDefault();
        } else if (e.key === 'z' && (e.metaKey || e.ctrlKey)) {
          this.undo();
          e.preventDefault();
        }
      },
      { signal }
    );
  }

  private initGameEvents(signal: AbortSignal) {
    this.stage.on('mousedown', (e) => {
      if (e.evt.buttons !== 1) return;
      if (e.evt.shiftKey) {
        this.startDrag();
      } else {
        if (this._state.brushMode) this.startLine();
      }
    });

    this.stage.on('mousemove', (e) => {
      e.evt.preventDefault();
      this.moveLine();
      this.drag();
    });

    this.stage.on('mouseup', () => {
      this.endLine();
      this.endDrag();
    });

    const scaleBy = 1.03;
    this.stage.on('wheel', (e) => {
      e.evt.preventDefault();

      const oldScale = this.stage.scaleX();
      const pos = this.stage.getPointerPosition();
      if (!pos) return;

      const pointTo = {
        x: (pos.x - this.stage.x()) / oldScale,
        y: (pos.y - this.stage.y()) / oldScale,
      };

      // how to scale? Zoom in? Or zoom out?
      let direction = e.evt.deltaY > 0 ? -1 : 1;

      // when we zoom on trackpad, e.evt.ctrlKey is true
      // in that case lets revert direction
      if (e.evt.ctrlKey) {
        direction = -direction;
      }

      let newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;

      if (newScale < this._state.baseScale) newScale = this._state.baseScale;
      this._state.userScale = newScale;

      const x = -(pointTo.x - pos.x / newScale) * newScale;
      const y = -(pointTo.y - pos.y / newScale) * newScale;
      const newPos = this.boundFunc({ x, y }, newScale);

      this.stage.scale({ x: newScale, y: newScale });
      this.stage.position(newPos);
      this.emitter.emit('zoom');
    });

    let lastDist = 0;

    this.stage.on('touchmove', (e) => {
      if (!e.evt.touches) return;

      if (e.evt.touches.length === 1) {
        if (!this.currentLine) {
          if (this._state.brushMode) this.startLine();
        } else {
          this.moveLine(5);
        }
        return;
      }

      if (e.evt.touches.length !== 2) return;

      const touch1 = e.evt.touches[0];
      const touch2 = e.evt.touches[1];

      if (touch1 && touch2) {
        // unlike the pointer position, which has been processed by Konva,
        // the touch position is relative to the viewport.
        const containerRect = this.container.getBoundingClientRect();
        const p1 = {
          x: touch1.clientX - containerRect.x,
          y: touch1.clientY - containerRect.y,
        };
        const p2 = {
          x: touch2.clientX - containerRect.x,
          y: touch2.clientY - containerRect.y,
        };

        const pos = getCenter(p1, p2);

        const dist = getDistance(p1, p2);

        if (!lastDist) {
          lastDist = dist;
        }

        if (Math.abs(dist - lastDist) <= 2) {
          if (!this.dragStartPos) {
            this.startDrag();
          } else {
            this.drag();
          }
          // drag
        } else {
          this.endDrag();
          // zoom
          const oldScale = this.stage.scaleX();
          const pointTo = {
            x: (pos.x - this.stage.x()) / oldScale,
            y: (pos.y - this.stage.y()) / oldScale,
          };

          let newScale = oldScale * (dist / lastDist);
          if (newScale < this._state.baseScale)
            newScale = this._state.baseScale;
          this._state.userScale = newScale;

          const x = -(pointTo.x - pos.x / newScale) * newScale;
          const y = -(pointTo.y - pos.y / newScale) * newScale;
          const newPos = this.boundFunc({ x, y }, newScale);

          this.stage.scale({ x: newScale, y: newScale });
          this.stage.position(newPos);
          this.emitter.emit('zoom');
        }

        lastDist = dist;
      }
    });

    this.stage.on('touchend', () => {
      lastDist = 0;
      this.endLine();
      this.endDrag();
    });

    this.stage.on('click tap', () => {
      this.container.focus();
    });

    this.container.addEventListener(
      'keydown',
      (e) => {
        if (e.key === 'z' && (e.metaKey || e.ctrlKey)) {
          this.undo();
          e.preventDefault();
        }
      },
      { signal }
    );

    this.container.addEventListener(
      'keyup',
      (e) => {
        if (e.key === 'Shift') {
          this.endDrag();
          e.preventDefault();
        }
      },
      { signal }
    );
  }

  private boundFunc(pos: { x: number; y: number }, scale: number) {
    const [w, h] = [this._state.sceneWidth, this._state.sceneHeight];
    const x = Math.min(0, Math.max(pos.x, w * (this._state.baseScale - scale)));
    const y = Math.min(0, Math.max(pos.y, h * (this._state.baseScale - scale)));
    return { x, y };
  }

  private get editMode() {
    return this.mode === 'edit';
  }

  setBackgroundFromURL(url: string) {
    const img = new Image();
    img.onload = () => {
      const bg = new KonvaImage({
        x: 0,
        y: 0,
        image: img,
        width: this._state.sceneWidth,
        height: this._state.sceneHeight,
      });
      this.layers.bg.add(bg);
    };
    img.src = url;
  }

  addCircle(radius = 25, fill = '#3988FF', opacity = 0.5) {
    const circle = new KonvaCircle({
      id: uuidv4(),
      x: this._state.sceneWidth / 2,
      y: this._state.sceneHeight / 2,
      draggable: this.editMode,
      name: 'drawing',
      radius,
      fill,
      opacity,
    });
    this.addDrawingShape(circle);
    this.transformer.nodes([circle]);
  }

  addRect(width = 50, height = 50, fill = '#3988FF', opacity = 0.5) {
    const rect = new KonvaRect({
      id: uuidv4(),
      x: (this._state.sceneWidth - width) / 2,
      y: (this._state.sceneHeight - height) / 2,
      draggable: this.editMode,
      name: 'drawing',
      width,
      height,
      fill,
      opacity,
    });
    this.addDrawingShape(rect);
    this.transformer.nodes([rect]);
  }

  startLine() {
    if (!this._state.brushMode) return;
    const pos = this.stage.getRelativePointerPosition();
    if (!pos) return;
    this.currentLine = {
      buffer: new KonvaLine({
        id: uuidv4(),
        // the addtional pos+1 can draw a "point" without moving
        // points: [pos.x, pos.y, pos.x + 1, pos.y + 1],
        points: [pos.x, pos.y],
        stroke: this._state.brushColor,
        strokeWidth: this._state.brushSize * 2,
        lineCap: 'round',
        lineJoin: 'round',
        name: 'drawing',
        draggable: this.editMode,
        globalCompositeOperation:
          this._state.brushMode === 'brush' ? 'source-over' : 'destination-out',
      }),
      added: false,
    };
  }

  moveLine(minMoveDistance = 0) {
    if (!this.currentLine) return;
    const pos = this.stage.getRelativePointerPosition();
    if (!pos) return;
    if (minMoveDistance) {
      const [prevX, prevY] = this.currentLine.buffer.points().slice(-2);
      const dist = getDistance({ x: prevX, y: prevY }, pos);
      if (dist < minMoveDistance) {
        this.logger.info('touchmove distance is too small', {
          prev: { x: prevX, y: prevY },
          curr: pos,
          distance: dist,
        });
        return;
      }
    }
    if (!this.currentLine.added) {
      this.addDrawingShape(this.currentLine.buffer);
      this.currentLine.added = true;
    }
    const points = this.currentLine.buffer.points().concat([pos.x, pos.y]);
    this.currentLine.buffer.points(points);
    this.layers.draw.batchDraw();
  }

  endLine() {
    this.currentLine = null;
  }

  startDrag() {
    const pos = this.stage.getPointerPosition();
    if (!pos) return;
    this.container.style.cursor = 'move';
    this.dragStartPos = {
      x: pos.x - this.stage.x(),
      y: pos.y - this.stage.y(),
    };
  }

  drag() {
    if (!this.dragStartPos) return;
    const pos = this.stage.getPointerPosition();
    if (!pos) return;
    const newPos = this.boundFunc(
      {
        x: pos.x - this.dragStartPos.x,
        y: pos.y - this.dragStartPos.y,
      },
      this._state.userScale
    );
    this.stage.position(newPos);
  }

  endDrag() {
    this.setDefaultCursor();
    this.dragStartPos = null;
  }

  addDrawingShape(
    shape: KonvaShape,
    layer: 'draw' | 'base' = 'draw',
    pushHistory = layer === 'draw'
  ) {
    if (pushHistory) {
      this.pushHistory();
    }
    this.layers[layer].add(shape);
  }

  undo() {
    if (this.history.length === 0) return;
    const data = this.popHistory();
    if (!data) return;
    const nodes = this.getDrawingNodes();
    const tranformingNodeIDs = this.transformer.nodes().map((node) => node.id);
    for (const node of nodes) {
      this.deleteDrawingShape(node, false);
    }
    const nextTransformingNodes = [];
    for (const nodeJSON of data) {
      const node = KonvaNode.create(nodeJSON) as KonvaShape | KonvaGroup;
      if (tranformingNodeIDs.includes(node.id)) {
        nextTransformingNodes.push(node);
      }
      this.layers.draw.add(node);
    }
    this.transformer.nodes(nextTransformingNodes);
  }

  deleteDrawingShape(tNode: KonvaShape | KonvaGroup, pushHistory = true) {
    if (pushHistory) this.pushHistory();
    const nodes = this.transformer.nodes();
    this.transformer.nodes(nodes.filter((node) => node !== tNode));
    tNode.destroy();
    this.layers.draw.batchDraw();
  }

  private pushHistory() {
    this.history.push(this.getDrawingNodes().map((node) => node.toJSON()));
    this._state.historyLength = this.history.length;
  }

  private popHistory() {
    const children = this.history.pop();
    this._state.historyLength = this.history.length;
    return children;
  }

  private getDrawingNodes() {
    return this.layers.draw.getChildren(
      (item) => item.getClassName() !== 'Transformer'
    );
  }

  get state() {
    return this._state;
  }

  toggleBrushMode(mode: 'brush' | 'eraser' | false) {
    this._state.brushMode = mode;
    this.setDefaultCursor();
  }

  setBrushSize(size: number) {
    this._state.brushSize = size;
  }

  setTool(tool: { name: string; icon?: string } | null) {
    this.tool = tool;
    this.setDefaultCursor();
  }

  private setDefaultCursor() {
    if (this.tool?.icon && this._state.brushMode === 'brush') {
      this.container.style.cursor = `url(${this.tool.icon}), auto`;
    } else {
      this.container.style.cursor = 'default';
    }
  }

  export() {
    return {
      nodes: this.getDrawingNodes().map((node) => node.toJSON()),
      width: this._state.sceneWidth,
      height: this._state.sceneHeight,
    };
  }

  import(
    drawing: NonNullable<ModelsDrawToWinMatchTool['drawing']>,
    layer: 'draw' | 'base'
  ) {
    for (const data of drawing.nodes) {
      this.addDrawingShape(KonvaNode.create(data), layer, false);
    }
  }

  resetUserScale() {
    this._state.userScale = this._state.baseScale;
    this.stage.scale({ x: this._state.baseScale, y: this._state.baseScale });
    this.stage.position({ x: 0, y: 0 });
    this.stage.draw();
  }

  grade() {
    this.resetUserScale();
    this.layers.base.draw();
    this.layers.base.moveToBottom();
    this.layers.base.visible(true);
    this.layers.base.draw();

    const baseImageData = this.layers.base
      .getContext()
      .getImageData(
        0,
        0,
        this.layers.base.canvas.width,
        this.layers.base.canvas.height
      );

    const drawImageData = this.layers.draw
      .getContext()
      .getImageData(
        0,
        0,
        this.layers.draw.canvas.width,
        this.layers.draw.canvas.height
      );

    const diffImageData = new ImageData(
      new Uint8ClampedArray(baseImageData.data.length),
      baseImageData.width,
      baseImageData.height,
      { colorSpace: baseImageData.colorSpace }
    );

    let total = 0;
    let correct = 0;
    let wrong = 0;

    for (let i = 0; i < baseImageData.data.length; i += 4) {
      const baseRGBA = baseImageData.data.slice(i, i + 4).some((v) => !!v);
      const drawRGBA = drawImageData.data.slice(i, i + 4).some((v) => !!v);
      if (baseRGBA) {
        updateData(diffImageData.data, i, DrawingStageManager.colors.missed);
        total += 1;
      } else {
        diffImageData.data[i] = 0;
        diffImageData.data[i + 1] = 0;
        diffImageData.data[i + 2] = 0;
        diffImageData.data[i + 3] = 0;
      }
      if (baseRGBA && drawRGBA) {
        updateData(diffImageData.data, i, DrawingStageManager.colors.correct);
        correct += 1;
      }
      if (!baseRGBA && drawRGBA) {
        updateData(diffImageData.data, i, DrawingStageManager.colors.wrong);
        wrong += 1;
      }
    }

    const canvas = document.createElement('canvas');
    canvas.width = baseImageData.width;
    canvas.height = baseImageData.height;
    const ctx = canvas.getContext('2d');
    ctx?.putImageData(diffImageData, 0, 0);
    const image = new KonvaImage({
      x: 0,
      y: 0,
      image: canvas,
      width: this._state.sceneWidth,
      height: this._state.sceneHeight,
    });
    this.layers.diff.add(image);
    this.layers.diff.visible(true);
    this.layers.base.visible(false);

    const result = {
      correct: correct / total,
      missed: (total - correct) / total,
      wrong: wrong / total,
    };

    this.logger.info('grade result', {
      correct,
      total,
      wrong,
      percentage: result,
    });

    return result;
  }

  destroy() {
    this.stage.destroy();
  }
}
