import shuffle from 'lodash/shuffle';
import { useMemo } from 'react';
import { useEffectOnce } from 'react-use';
import { proxy } from 'valtio';

import {
  type DtoTTSRenderRequest,
  EnumsTTSCacheControl,
  EnumsTTSRenderPolicy,
} from '@lp-lib/api-service-client/public';
import type { MemoryMatchBlock } from '@lp-lib/game';
import { type BlockOutputsDesc } from '@lp-lib/game/src/block-outputs';
import { type Logger } from '@lp-lib/logger-base';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { sleep } from '../../../../utils/common';
import { markSnapshottable, useSnapshot } from '../../../../utils/valtio';
import {
  lvoLocalCacheWarm,
  LVOLocalPlayer,
} from '../../../VoiceOver/LocalLocalizedVoiceOvers';
import { getStingerPrompt } from '../../apis/StingerControl';
import {
  CommonButton,
  type CommonButtonVariant,
  GamePlayButton,
} from '../../design/Button';
import { CorrectAnimationWithLayout } from '../../design/CorrectAnimation';
import {
  type BlockDependencies,
  type IBlockCtrl,
  type PlaygroundPlaybackProtocol,
} from '../../types';
import { getOutputSchema } from './outputs';

type State = {
  status: 'init' | 'present' | 'play' | 'inform' | 'complete';
  visibility: 'visible' | 'hidden';
  cardState: Record<
    string,
    {
      status: 'default' | 'selected' | 'incorrect' | 'correct' | 'matched';
      text: string;
    }
  >;
  left: string[];
  right: string[];
  selected: [string | null, string | null];
  matched: string[];
  mismatchCount: number;
};

export class MatchBlockControlAPI implements IBlockCtrl {
  private _state = markSnapshottable(
    proxy<State>({
      status: 'init',
      visibility: 'hidden',
      cardState: {},
      left: [],
      right: [],
      selected: [null, null],
      matched: [],
      mismatchCount: 0,
    })
  );
  private matchLookup = new Map<string, string>();
  private resolvedTTS: {
    stinger: Nullable<DtoTTSRenderRequest>;
    prompt: Nullable<DtoTTSRenderRequest>;
    completion: Nullable<DtoTTSRenderRequest>;
  } = {
    stinger: null,
    prompt: null,
    completion: null,
  };
  private delegate: Nullable<PlaygroundPlaybackProtocol>;
  private logger: Logger;
  private schema: BlockOutputsDesc;

  constructor(
    private block: MemoryMatchBlock,
    private deps: BlockDependencies
  ) {
    this.logger = deps.getLogger('match-block');
    this.schema = getOutputSchema(block);
  }

  get state() {
    return this._state;
  }

  async preload() {
    if (!this.block.fields.personalityId) return;
    this.resolvedTTS.stinger = await this.stingerTTS();
    this.resolvedTTS.prompt = await this.promptTTS();
    this.resolvedTTS.completion = await this.completionTTS();
    lvoLocalCacheWarm(this.resolvedTTS.completion);
    await lvoLocalCacheWarm(this.resolvedTTS.stinger);
    await lvoLocalCacheWarm(this.resolvedTTS.prompt);
  }

  async initialize(preloaded: Promise<void>) {
    await preloaded;

    const left = [];
    const right = [];
    const matchLookup = new Map<string, string>();
    const cards = this.block.fields.cardPairs ?? [];
    const cardState: State['cardState'] = {};
    for (let i = 0; i < cards.length; i++) {
      const card = cards[i];
      const leftId = `l:${i}`;
      const rightId = `r:${i}`;
      cardState[leftId] = {
        status: 'default',
        text: card.firstText ?? '',
      };
      cardState[rightId] = {
        status: 'default',
        text: card.secondText ?? '',
      };
      left.push(leftId);
      right.push(rightId);
      matchLookup.set(leftId, rightId);
      matchLookup.set(rightId, leftId);
    }
    this._state.left = shuffle(left);
    this._state.right = shuffle(right);
    this._state.cardState = cardState;
    this.matchLookup = matchLookup;
  }

  setDelegate(delegate: PlaygroundPlaybackProtocol) {
    this.delegate = delegate;
  }

  async present() {
    try {
      await this.deps.stingerControl.playBlockIntro(
        this.block,
        this.resolvedTTS.stinger
      );
    } catch (e) {
      this.logger.error('failed to play stinger TTS', e);
    }

    try {
      const player = new LVOLocalPlayer(this.resolvedTTS.prompt);
      const info = await player.playFromPool();
      await info?.trackStarted;
      this._state.visibility = 'visible';
      this._state.status = 'present';
      await info?.trackEnded;
    } catch (e) {
      this.logger.error(
        'failed to play question TTS, falling back to silence',
        e
      );
      this._state.visibility = 'visible';
      this._state.status = 'present';
      await sleep(3000);
    }
    this._state.status = 'play';
  }

  select(col: 'left' | 'right', id: string) {
    const index = col === 'left' ? 0 : 1;
    if (this._state.selected[index] === id) {
      this._state.selected[index] = null;
      this._state.cardState[id].status = 'default';
    } else {
      const current = this._state.selected[index];
      if (current) {
        this._state.cardState[current].status = 'default';
      }

      this._state.selected[index] = id;
      this._state.cardState[id].status = 'selected';
    }
    this.tryMatch();
  }

  tryMatch() {
    const [left, right] = this._state.selected;
    if (left && right) {
      const isMatch = this.matchLookup.get(left) === right;
      if (isMatch) {
        this._state.matched.push(left, right);
        (async () => {
          this._state.cardState[left].status = 'correct';
          this._state.cardState[right].status = 'correct';
          this.deps.sfxControl.play('rapidCorrect');
          await sleep(400);
          this._state.cardState[left].status = 'matched';
          this._state.cardState[right].status = 'matched';
        })();
        if (this._state.matched.length === this._state.left.length * 2) {
          this.inform();
        }
      } else {
        (async () => {
          this._state.mismatchCount += 1;
          if (
            await this.delegate?.blockDidOutput(
              this.schema.mismatchCount,
              this._state.mismatchCount
            )
          )
            return;
          this._state.cardState[left].status = 'incorrect';
          this._state.cardState[right].status = 'incorrect';
          this.deps.sfxControl.play('rapidWrong');
          await sleep(400);
          this._state.cardState[left].status = 'default';
          this._state.cardState[right].status = 'default';
        })();
      }
      this._state.selected = [null, null];
    }
  }

  async inform() {
    if (this.block.fields.pointsPerMatch === 0) {
      this._state.status = 'complete';
      return;
    }
    this._state.status = 'inform';
    try {
      const player = new LVOLocalPlayer(this.resolvedTTS.completion);
      const info = await player.playFromPool();
      await info?.trackEnded;
    } catch (e) {
      this.logger.error('failed to play result TTS', e);
    }
    this._state.status = 'complete';
  }

  async end() {
    this.deps.sfxControl.play('instructionHoverReadyButton');
    await this.delegate?.blockDidEnd();
  }

  private async stingerTTS(): Promise<Nullable<DtoTTSRenderRequest>> {
    if (!this.deps.stingerControl.shouldPreloadTTS(this.block)) return null;

    return this.makeTTSRenderRequest(
      getStingerPrompt(
        this.block.fields.text,
        "Match'em Up",
        'Quickly match pairs to win.'
      )
    );
  }

  private async promptTTS() {
    return this.makeTTSRenderRequest(this.block.fields.text);
  }

  private async completionTTS(): Promise<Nullable<DtoTTSRenderRequest>> {
    return this.makeTTSRenderRequest(
      getCompletionPrompt(this.block.fields.text)
    );
  }

  private async makeTTSRenderRequest(
    script: string
  ): Promise<Nullable<DtoTTSRenderRequest>> {
    const personalityId = this.block.fields.personalityId;
    if (!personalityId) return null;

    const resolved = await this.deps.commonVariableRegistry.render(script);
    return {
      script: resolved.script,
      personalityId,
      cacheControl: EnumsTTSCacheControl.TTSCacheControlShortLive,
      policy: EnumsTTSRenderPolicy.TTSRenderPolicyReadThrough,
    };
  }
}

export function MatchBlockPlayground(props: {
  block: MemoryMatchBlock;
  ctrl: MatchBlockControlAPI;
}) {
  const { text: prompt } = props.block.fields;
  const { status, visibility, left, right } = useSnapshot(props.ctrl.state);

  useEffectOnce(() => {
    props.ctrl.present();
  });

  return (
    <div className='relative w-full h-full min-h-0 flex flex-col'>
      <main
        className={`
          w-full flex-1 min-h-0 px-10 flex flex-col justify-center gap-5 sm:gap-10
          transition-opacity duration-500 ${
            visibility === 'visible' ? 'opacity-100' : 'opacity-0'
          }
        `}
      >
        <div className='text-white text-center break-words text-base sm:text-xl lg:text-2xl'>
          {prompt}
        </div>

        <div className='w-full flex gap-2'>
          <div className='w-1/2 min-w-0 flex flex-col gap-2'>
            {left.map((id) => (
              <MatchButton key={id} id={id} col='left' ctrl={props.ctrl} />
            ))}
          </div>
          <div className='w-1/2 min-w-0 flex flex-col gap-2'>
            {right.map((id) => (
              <MatchButton key={id} id={id} col='right' ctrl={props.ctrl} />
            ))}
          </div>
        </div>
      </main>

      <footer
        className={`
          w-full px-3 pt-3 pb-5 flex justify-center relative
          transition-opacity duration-500 ${
            visibility === 'visible' ? 'opacity-100' : 'opacity-0'
          }
        `}
      >
        <CommonButton
          variant='correct'
          onClick={() => props.ctrl.end()}
          className={
            status === 'inform' || status === 'complete'
              ? 'opacity-100'
              : 'opacity-0 pointer-events-off'
          }
          disabled={status === 'inform'}
        >
          Continue
        </CommonButton>
        {status === 'complete' && props.block.fields.pointsPerMatch > 0 && (
          <CorrectAnimationWithLayout onEnd={() => void 0} />
        )}
      </footer>
    </div>
  );
}

function MatchButton(props: {
  id: string;
  col: 'left' | 'right';
  ctrl: MatchBlockControlAPI;
}) {
  const { id, col, ctrl } = props;
  const { status, text } = useSnapshot(ctrl.state).cardState[id];

  const [variant, disabled]: [CommonButtonVariant, boolean] = useMemo(() => {
    switch (status) {
      case 'selected':
        return ['brand', false];
      case 'incorrect':
        return ['incorrect', true];
      case 'correct':
        return ['correct', true];
      case 'matched':
        return ['gray', true];
      default:
        return ['gray', false];
    }
  }, [status]);

  const handleClick = useLiveCallback(() => {
    props.ctrl.select(col, id);
  });

  return (
    <GamePlayButton
      variant={variant}
      styles={{
        size: 'w-full h-18',
        spacing: 'px-2 py-1',
      }}
      disabled={disabled}
      onClick={handleClick}
      className={`${status !== 'matched' ? 'disabled:opacity-100' : ''}`}
    >
      <span className='w-full text-xs font-bold text-white break-words'>
        {text}
      </span>
    </GamePlayButton>
  );
}

function getCompletionPrompt(context: string) {
  return `
\`\`\`openai { "model": "gpt-4o-mini", "temperature": 1, "maxTokens": 1024 }
You are a scriptwriter for an online platform called Luna Park. A user is playing a matching game where the must match card pairs. Assume they've found all the matches. Your job is to write a script that informs the user they are all done.

Your script should be short and creative. It should be positive and congratulatory, but not overly so. Assume the user has been playing games before this one, and that they will play more in the future, so the script should not be so final.

I will provide you context related to what the game was about. Never repeat the context in its entirety, but you may broadly reference the context in your script. Never include any information that is not directly related to the context.

<example>
Context: Connect the part names with their corresponding function.
Output: Hey, nice job! You've found everything.
</example>

<example>
Context: Match the database concepts on the left to their descriptions on the right
Output: Keep it up! Looks like you selected everything correctly!
</example>

Output only the script, with nothing before or after. 

Context: ${context}
\`\`\`
`.trim();
}
