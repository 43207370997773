import {
  type ModelsBlockOutput,
  type ModelsLogicSettings,
} from '@lp-lib/api-service-client/public';
import { getBlockOutputKey } from '@lp-lib/game/src/block-outputs';
import {
  BlockLogicSchema,
  type LogicAction,
  type LogicArg,
  type LogicCondition,
  type LogicRule,
} from '@lp-lib/game/src/logic';
import { type Logger } from '@lp-lib/logger-base';

import { assertExhaustive } from '../../../utils/common';

type BlockLogicDeps = {
  onJump: (toBlockId: string) => void;
};

export class BlockLogicEvaluator {
  private triggers: LogicRule[];
  constructor(
    logic: ModelsLogicSettings['blockLogic'][string],
    private logger: Logger
  ) {
    this.triggers = [];
    const result = BlockLogicSchema.safeParse(logic ?? { rules: [] });
    if (!result.success) {
      this.logger.warn('failed to parse logic settings', {
        errors: result.error.errors,
      });
      return;
    }

    const blockLogic = result.data;
    this.triggers.push(...blockLogic.rules);
  }

  eval(outputs: Record<string, ModelsBlockOutput>, deps: BlockLogicDeps) {
    for (const trigger of this.triggers) {
      if (this.evaluateCondition(trigger.condition, outputs)) {
        if (trigger.actions.length > 0) {
          // multiple actions are supported in the datamodel, but currently only one can be configured.
          // there is only one action, jump, and we permit only one jump per evaluation.
          this.executeAction(trigger.actions[0], deps);
          return;
        }
      }
    }
  }

  private evaluateCondition(
    condition: LogicCondition,
    outputs: Record<string, ModelsBlockOutput>
  ): boolean {
    switch (condition.type) {
      case 'stringCmp': {
        const args = condition.args.map((arg) => this.resolve(arg, outputs));
        if (args.some((arg) => arg === undefined)) {
          return false;
        }
        switch (condition.op) {
          case 'equal':
            return args[0] === args[1];
          case 'notEqual':
            return args[0] !== args[1];
          case 'beginsWith':
            return String(args[0]).startsWith(String(args[1]));
          case 'endsWith':
            return String(args[0]).endsWith(String(args[1]));
          case 'contains':
            return String(args[0]).includes(String(args[1]));
          case 'notContains':
            return !String(args[0]).includes(String(args[1]));
          default:
            assertExhaustive(condition);
            return false;
        }
      }
      case 'numberCmp': {
        const args = condition.args.map((arg) => this.resolve(arg, outputs));
        if (args.some((arg) => arg === undefined)) {
          return false;
        }
        switch (condition.op) {
          case 'eq':
            return args[0] === args[1];
          case 'neq':
            return args[0] !== args[1];
          case 'lt':
            return Number(args[0]) < Number(args[1]);
          case 'gt':
            return Number(args[0]) > Number(args[1]);
          case 'lte':
            return Number(args[0]) <= Number(args[1]);
          case 'gte':
            return Number(args[0]) >= Number(args[1]);
          default:
            assertExhaustive(condition);
            return false;
        }
      }
      case 'enumCmp': {
        const args = condition.args.map((arg) => this.resolve(arg, outputs));
        if (args.some((arg) => arg === undefined)) {
          return false;
        }
        switch (condition.op) {
          case 'is':
            return args[0] === args[1];
          case 'isNot':
            return args[0] !== args[1];
          default:
            assertExhaustive(condition);
            return false;
        }
      }
      case 'always':
        return true;
    }
  }

  private resolve(arg: LogicArg, outputs: Record<string, ModelsBlockOutput>) {
    switch (arg.type) {
      case 'output':
        const key = getBlockOutputKey(arg.blockId, arg.outputName);
        if (!(key in outputs)) return undefined;
        return outputs[key].value;
      case 'constant':
        return arg.value;
      default:
        assertExhaustive(arg);
        return undefined;
    }
  }

  private executeAction(action: LogicAction, deps: BlockLogicDeps) {
    switch (action.type) {
      case 'jump':
        deps.onJump(action.props.toBlockId);
        break;
      default:
        assertExhaustive(action.type);
        break;
    }
  }
}
