import { type NavigateFunction } from '@remix-run/react';

import { type Logger } from '@lp-lib/logger-base';

import { type PlayCursor } from '../PlayCursor';

export class PauseManagement {
  private aborter = new AbortController();

  constructor(logger: Logger, cursor: PlayCursor, navigate: NavigateFunction) {
    document.addEventListener(
      'visibilitychange',
      () => {
        if (document.hidden) {
          logger.info('document hidden, redirecting to overworld');
          navigate(`/game-packs/${cursor.gamePack.id}/overworld`);
        } else {
          logger.info('document unhidden');
        }
      },
      { signal: this.aborter.signal }
    );
  }

  destroy() {
    this.aborter.abort();
  }
}
